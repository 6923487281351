import React, { useEffect, useState } from "react";
import "./sidepanel/SidePanel.scss";
import "./FeaturesModal.scss";
import { Modal } from "./Modal";
import type { MenuProps } from "antd";
import { Button, Menu, Radio, Breadcrumb, Flex, theme, Tag, List, Card } from "antd";
import { AppState, BinaryFileData, BinaryFiles, ImagoProps } from "../types";
import { GoogleDrivePanel } from "./GoogleDrivePanel";
import { CollaborationPanel } from "./CollaborationPanel";
import Library from "../data/library";
import { NonDeletedImagoElement, PointerType } from "../element/types";
import { LibraryMenu } from "./LibraryMenu";
import { ImageSearch } from "./ImageSearch";
import { ImageGallery } from "./ImageGallery";
import { ScreenSharing } from "./ScreenSharing";
import { getCatName, Templates } from "./TemplateSelector";
import { CloseIcon, IconCloseWin, SearchIcon } from "./icons";
import { createFile, getMimeType } from "../data/blob";
import { Mermaid } from "./Mermaid";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { IconChevronLeftCircle, IconChevronRightCircle } from "./newIcons";
import { useDevice } from "./App";
import { MarketPlace } from "./MarketPlace";
import { InsertEmbed } from "./InsertEmbed";
import Meta from "antd/es/card/Meta";
import { GetLoginedUser } from "../utils";
import { t } from "../i18n";
import { getTemplateName } from "./TemplatesImage";
import { getCurrBoardMode, isCollabtionHost } from "../imago-app/data/localStorage";
import { BOARD_MODE } from "../constants";
import { TranslateAssist } from "./TranslateAssist";




export const FeaturesModal: React.FC<{
  appState: AppState;
  listLimit?: number;
  setAppState: React.Component<any, AppState>["setState"];
  onInsertElements: (elements: readonly NonDeletedImagoElement[]) => void;
  focusContainer: () => void;
  addFileToCanvas: (file: File) => Promise<void>;
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  library: Library;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
  onImageAction: (data: {
    pointerType: PointerType | null;
    callback: () => void;
  }) => void;
  files: BinaryFiles;
  deleteFiles: (fileIds: string[]) => void;
  addFiles: (files: BinaryFileData[]) => void;
  isCollaborating: boolean;
  id: string;
  onLibraryItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  onLibraryPageChange: (page: number, pageSize: number) => void;
  onRoomCreated: () => void;
  onMermaidInsert: (file: File) => void
}> = ({
  appState,
  listLimit = 6,
  setAppState,
  onInsertElements,
  focusContainer,
  addFileToCanvas,
  libraryReturnUrl,
  library,
  scanUploadImage,
  onImageAction,
  files,
  deleteFiles,
  addFiles,
  isCollaborating,
  id,
  onLibraryItemTouchEnd,
  onLibraryPageChange,
  onRoomCreated,
  onMermaidInsert
}) => {
    const device = useDevice();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [keywords, setKeyWords] = useState<string>("");
    const [isCategory, setIsCategory] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    const [templates, setTemplates] = useState<any[]>();
    const [isCanUseFeature, setIsCanUseFeature] = useState<boolean>(true);
    const [isStartLib, setIsStartLib] = useState<boolean>(true);

    const [templatesTotal, setTemplateTotal] = useState(0);
    const host = "";
    const user = GetLoginedUser();

    const menuRef = React.useRef<HTMLDivElement | null>(null);

    const width = device.isMobile ? window.innerWidth - 40 : window.innerWidth / 2;
    const height = device.isMobile ? window.innerHeight - 40 : window.innerHeight * 4 / 5;
    const top = device.isMobile ? 20 : window.innerHeight / 10;
    const left = device.isMobile ? 20 : window.innerWidth / 4;

    const currBoardMode = getCurrBoardMode()
    const items = [
      {
        label: t("winTabList.template"),
        key: "template",
      },
      {
        label: t("winTabList.imageSearch"),
        key: "imageSearch",
      },
      {
        label: t("winTabList.translate"),
        key: "translate",
      },
      {
        label: t("winTabList.collaboration"),
        key: "collaboration",
      },
      {
        label: t("winTabList.googleDrive"),
        key: "googleDrive",
      },
      {
        label: t("winTabList.library"),
        key: "library",
      },

      {
        label: t("winTabList.imageGallery"),
        key: "imageGallery",
      },
      {
        label: t("winTabList.links"),
        key: "links",
      },
      {
        label: t("winTabList.shareScreen"),
        key: "shareScreen",
      },
      {
        label: t("winTabList.marketPlace"),
        key: "marketPlace",
      },
    ] as const;


    const onMenuItemClick = (key: typeof items[number]["key"]) => {
      setAppState({
        openSidebar: key,
        lastOpenSidebar: key,
      });
    };

    const NormalTabWidget = ({ m }: { m: any }) => {
      return <button className={appState.openSidebar == m.key ? "btnActive" : "btnNormal"} key={m.key} onClick={() => onMenuItemClick(m.key)}>{m.label}</button>
    }

    const DisabledTabWidget = ({ m }: { m: any }) => {
      return <button disabled className={"btnNormal btn-disabled"} key={m.key} onClick={() => onMenuItemClick(m.key)}>{m.label}</button>
    }

    const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode == 13) {
        setKeyWords(event.target.value);
      }
    };
    const isHost = isCollabtionHost()
    const [categoryId, setCategoryId] = useState<string>();
    const [categoriesName, setCategoriesName] = useState<string>();


    const getTempList = async () => {
      try {
        const response = await fetch(
          `${host}/api/v1/categories/${categoryId}/templates?page=${page}&limit=${listLimit}&key=${keywords}`,
          {
            headers: {
              Authorization: `Bearer ${user?.authorization}`,
            },
          },
        ).then(async (response) => {
          let templates = (await response.json()).data.templates;
          setTemplates(templates);

        });
      } catch (error: any) { }
    }

    // Clicking on category then navigating to template
    const handleCategoryCardClick = async (
      item: any,
      categoryId: string,
      categoriesName: string,) => {

      setCategoryId(categoryId)
      setOpenModal(false);
      setIsCategory(false);
      setCategoriesName(getCatName(item));
      getTempList();
    };

    const MaskWidget = () => {
      return (<div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "#ccc", zIndex: 1, opacity: 0.8 }}></div>)
    }

    // Clicking on templates then navigating to board
    const handleTemplateCardClick = async (tpl: string) => {
      setOpenModal(false);
      try {
        const request = await fetch(`/api/v1/download/templates?path=${tpl}`, {
          headers: {
            Authorization: `Bearer ${appState.userInfo?.authorization}`,
          },
        });
        const data = await request.blob();
        await addFileToCanvas(createFile(data, getMimeType(data), "a"));
      } catch (error: any) { }
    };

    const backToCategory = () => {
      setIsCategory(true)
    };

    const handleMenuScroll = (direction: "left" | "right") => {
      if (!menuRef?.current) return;
      if (direction == "left") {
        menuRef.current.scrollLeft -= 100;
      } else {
        menuRef.current.scrollLeft += 100;
      }
    }


    useEffect(() => {
      getTempList()
    }, [page, categoryId, keywords, listLimit]);

    useEffect(() => {
      (async () => {
        let response = await fetch(
          `${host}/api/v1/stats/categories/${categoryId}/templates`,
          {
            headers: {
              Authorization: `Bearer ${user?.authorization}`,
            },
          },
        );
        setTemplateTotal((await response.json()).data);

      })();
    }, [categoryId, keywords]);


    useEffect(() => {

      if (!isCollaborating) {
        if (user?.roleEn === "Pro") {
          setIsCanUseFeature(true)
        } else {
          setIsCanUseFeature(false)
        }

      } else {
        if (isHost) {
          setIsCanUseFeature(true)
        } else {
          setIsCanUseFeature(false)
        }
      }

    }, []);

    return (
      <Popover top={top} left={left} onCloseRequest={() => {
        if (!appState.showModalWin) {
          setAppState({ openSidebar: null })
        }
      }}>
        <Island className="features-modal" style={{ width, height }}>
          {/* <Modal labelledBy="" onCloseRequest={() => {setAppState({openSidebar:null})}} maxWidth={900}> */}
          <div className="features-modal-close"><a href={undefined} onClick={() => { setAppState({ openSidebar: null }) }}>{CloseIcon}</a></div>
          <div className="features-modal-menu">
            <Button type="text" onClick={() => handleMenuScroll("left")}>{IconChevronLeftCircle}</Button>
            <div className="items" ref={menuRef}>
              {items.map((m, i) => {
                if (m.key === "marketPlace" || m.key === "links") {
                  if (isCollaborating) {
                    if (isHost) {
                      return <NormalTabWidget m={m} />
                    } else {
                      return <DisabledTabWidget m={m} />
                    }
                  } else {
                    return <NormalTabWidget m={m} />
                  }
                } else if (m.key === "imageSearch" || m.key === "shareScreen") {
                  if (user?.roleEn === "Pro") {
                    if (isCollaborating) {
                      if (isHost) {
                        return <NormalTabWidget m={m} />
                      } else {
                        return <DisabledTabWidget m={m} />
                      }
                    } else {
                      return <NormalTabWidget m={m} />
                    }

                  } else {
                    return <DisabledTabWidget m={m} />
                  }
                } else if (m.key === "template" || m.key === "library" || m.key === "googleDrive") {
                  if (isCollaborating) {
                    if (isHost) {
                      return <NormalTabWidget m={m} />
                    } else {
                      if (currBoardMode == BOARD_MODE.warRoom) {
                        return <NormalTabWidget m={m} />
                      } else {
                        return <DisabledTabWidget m={m} />
                      }
                    }
                  } else {
                    return <NormalTabWidget m={m} />
                  }

                } else if (m.key === "imageGallery") {
                  if (isCollaborating) {
                    if (isHost) {
                      return <NormalTabWidget m={m} />
                    } else {
                      if (currBoardMode == BOARD_MODE.classRoom) {
                        return <DisabledTabWidget m={m} />
                      } else {
                        return <NormalTabWidget m={m} />
                      }
                    }
                  } else {
                    return <NormalTabWidget m={m} />
                  }
                } else {
                  return <button className={appState.openSidebar == m.key ? "btnActive" : "btnNormal"} key={m.key} onClick={() => onMenuItemClick(m.key)}>{m.label}</button>
                }

              })}
            </div>
            <Button type="text" onClick={() => handleMenuScroll("right")}>{IconChevronRightCircle}</Button>
          </div>
          <div className="features-modal-content">
            {appState.openSidebar === "template" ? (
              <div style={{ position: "relative" }} className="template-modal">
                {isCollaborating ? (<>{!isHost && currBoardMode != BOARD_MODE.warRoom && (<MaskWidget />)}</>) : (<>{ }</>)}

                <div style={{ display: "none" }}>
                  <div className="search">
                    {SearchIcon}
                    <input
                      placeholder={t("home_welcome.search_template_by_name")}
                      onKeyDown={handleSearch}
                    ></input>
                  </div>
                </div>
                {isCategory == true ? (
                  <div style={{ display: "none" }}>
                    <Flex gap="middle" align="center" justify="space-between">
                      <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>
                          <button className="button-category" onClick={backToCategory}>{t("home_welcome.categories")}</button>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Flex>
                  </div>) : (
                  <div style={{ display: "none" }}>
                    <Flex gap="middle" align="center" justify="space-between">
                      <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>
                          <button className="button-category" onClick={backToCategory}>{t("home_welcome.categories")}</button>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{categoriesName}</Breadcrumb.Item>
                      </Breadcrumb>
                    </Flex>
                  </div>)}
                {isCategory == true ? (
                  <Templates
                    keywords={keywords}
                    listColumn={3}
                    handleCardClick={(item) => handleCategoryCardClick(
                      item,
                      item.id,
                      item.name)}
                    listLimit={6}
                    showMenu={false}
                  ></Templates>) : (
                  <div className="content">
                    <h2>{categoriesName}</h2>
                    <div className="list">
                      <List
                        grid={{ xs: 1, sm: 2, md: 2, column: 3 }}
                        pagination={
                          templatesTotal > listLimit
                            ? {
                              onChange: (page) => {
                                setPage(page);
                              },
                              pageSize: listLimit,
                              total: templatesTotal,
                              current: page

                            }
                            : false
                        }

                        dataSource={templates}

                        renderItem={(item) => (
                          <Card
                            hoverable
                            key={item.id}
                            style={{ margin: "10px" }}
                            cover={
                              <div className="card-cover">
                                <img
                                  alt={item.name}
                                  src={`/api/v1/download/template-images?path=${item.snapshortUrl}`}
                                />

                              </div>
                            }

                            onClick={() => handleTemplateCardClick(item.source)
                            }
                          >
                            <Meta title={getTemplateName(item)} />
                          </Card>
                        )}
                      />
                    </div>
                  </div>)}
              </div>
            ) : appState.openSidebar === "googleDrive" ? (
              <GoogleDrivePanel
                setAppState={setAppState}
                appState={appState}
                id={"GoogleDrivePanel"}
              />
            ) : appState.openSidebar === "collaboration" ? (
              <CollaborationPanel
                setAppState={setAppState}
                appState={appState}
                id={"CollaborationPanel"}
                onRoomCreated={onRoomCreated}
              />
            ) : appState.openSidebar === "library" ? (
              <LibraryMenu
                appState={appState}
                setAppState={setAppState}
                onInsertElements={onInsertElements}
                libraryReturnUrl={libraryReturnUrl}
                focusContainer={focusContainer}
                library={library}
                id={id}
                onItemTouchEnd={onLibraryItemTouchEnd}
                onPageChange={onLibraryPageChange}
              />
            ) : appState.openSidebar === "imageSearch" ? (
              <ImageSearch
                appState={appState}
                setAppState={setAppState}
                id={id}
              ></ImageSearch>
            ) : appState.openSidebar === "imageGallery" ? (
              <ImageGallery
                appState={appState}
                setAppState={setAppState}
                scanUploadImage={scanUploadImage}
                onImageAction={onImageAction}
                files={files}
                deleteFiles={deleteFiles}
                isCollaborating={isCollaborating}
                addFiles={addFiles}
                id={id}
              />
            ) : appState.openSidebar === "links" ? (
              <InsertEmbed
                appState={appState}
                setAppState={setAppState}
              />
            ) : appState.openSidebar === "shareScreen" ? (
              <ScreenSharing
                appState={appState}
                setAppState={setAppState}
                id={id}
              />
            ) : appState.openSidebar === "marketPlace" ? (
              <MarketPlace
                appState={appState}
                setAppState={setAppState}
              />
            ) : appState.openSidebar === "translate" ? (
              <TranslateAssist
                appState={appState}
                setAppState={setAppState}
              />
            ) : (
              <></>
            )}
          </div>
        </Island>
        {/* </Modal> */}
      </Popover>
    );
  };


