import {
  copyBlobToClipboardAsPng,
  copyTextToSystemClipboard,
} from "../clipboard";
import jsPDF from 'jspdf';
import { DEFAULT_EXPORT_PADDING, EXPORT_DATA_TYPES, MIME_TYPES } from "../constants";
import { FileId, NonDeletedImagoElement } from "../element/types";
import { t } from "../i18n";
import { exportToCanvas, exportToSvg } from "../scene/export";
import { ExportType } from "../scene/types";
import { AppState, BinaryFileData, BinaryFiles, Page } from "../types";
import { canvasToBlob } from "./blob";
import { fileSave, FileSystemHandle } from "./filesystem";
import { getExportedDataState, serializeAsJSON, serializeAsJSONPage } from "./json";
import JSZip from "jszip";
import saveAs from "file-saver";
import { useState } from "react";
import { getItemWithExpiration, getPageDataByKeyFromLocalStorage, getPageListFromStorage } from "../imago-app/data/localStorage";
import { getNonDeletedElements } from "../element";
import { isInitializedImageElement } from "../element/typeChecks";
import { LocalData } from "../imago-app/data/LocalData";
import { ExportedDataState } from "./types";
import { createFolderAndUploadFiles, uploadGoogleDrive, uploadMultipleGoogleDrive } from "../components/GoogleDriveApi";
// import FileSaver from "file-saver";

var FileSaver = require('file-saver');

export { loadFromBlob } from "./blob";
export { loadFromJSON, saveAsJSON } from "./json";


const generateFileName = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `file-${year}${month}${day}-${hours}${minutes}${seconds}`;
}



async function blobToArrayBuffer(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}


const sendImageToReactNative = (blob: Blob, options: Object) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    const base64data = reader.result;
    const message = JSON.stringify({ options, fileContent: base64data });
    window.ReactNativeWebView.postMessage(message);
  };
};



export const exportCanvas = async (
  type: Omit<ExportType, "backend">,
  elements: readonly NonDeletedImagoElement[],
  appState: AppState,
  files: BinaryFiles,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    name,
    fileHandle = null,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name: string;
    fileHandle?: FileSystemHandle | null;
  },
) => {
  if (elements.length === 0) {
    throw new Error(t("alerts.cannotExportEmptyCanvas"));
  }
  if (type === "svg" || type === "clipboard-svg") {
    const tempSvg = await exportToSvg(
      elements,
      {
        exportBackground,
        exportWithDarkMode: appState.exportWithDarkMode,
        viewBackgroundColor,
        exportPadding,
        exportScale: appState.exportScale,
        exportEmbedScene: appState.exportEmbedScene && type === "svg",
      },
      files,
    );
    if (type === "svg") {
      const options = {
        description: "Export to SVG",
        name,
        extension: appState.exportEmbedScene ? "imago.svg" : "svg",
        fileHandle,
      }

      if (window.electron) {
        const arrayBuffer = await blobToArrayBuffer(new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }));
        window.electron.saveFile(arrayBuffer, options);
        return null;
      } else {

        if (window.ReactNativeWebView) {
          sendImageToReactNative(
            new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
            options,
          );
          return null;
        } else {
          return await fileSave(
            new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
            {
              description: "Export to SVG",
              name,
              extension: appState.exportEmbedScene ? "imago.svg" : "svg",
              fileHandle,
            },
          );
        }
      }

    } else if (type === "clipboard-svg") {
      await copyTextToSystemClipboard(tempSvg.outerHTML);
      return;
    }
  }

  const tempCanvas = await exportToCanvas(elements, appState, files, {
    exportBackground,
    viewBackgroundColor,
    exportPadding,
  });
  tempCanvas.style.display = "none";
  document.body.appendChild(tempCanvas);

  if (type === "png") {
    let blob = await canvasToBlob(tempCanvas);
    tempCanvas.remove();
    if (appState.exportEmbedScene) {
      blob = await (
        await import(/* webpackChunkName: "image" */ "./image")
      ).encodePngMetadata({
        blob,
        metadata: serializeAsJSON(elements, appState, files, "local"),
      });
    }

    const options = {
      description: "Export to PNG",
      name,
      extension: appState.exportEmbedScene ? "imago.png" : "png",
      fileHandle,
    }

    if (window.electron) {
      const arrayBuffer = await blobToArrayBuffer(blob);
      window.electron.saveFile(arrayBuffer, options);
      return null;
    } else {

      if (window.ReactNativeWebView) {
        sendImageToReactNative(blob, options);
        return null;
      } else {
        return await fileSave(blob, {
          description: "Export to PNG",
          name,
          extension: appState.exportEmbedScene ? "imago.png" : "png",
          fileHandle,
        });
      }


    }


  } else if (type === "pdf") {

    const pdf = new jsPDF();
    const canvasDataUrl = tempCanvas.toDataURL("image/jpeg");
    pdf.addImage(canvasDataUrl, "JPEG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

    pdf.setProperties({
      title: name,
    });

    const options = {
      description: "Export to pdf",
      name,
      extension: appState.exportEmbedScene ? "imago.pdf" : "pdf",
      fileHandle,
    }

    const blob = await pdf.output("blob");
    return await fileSave(blob, {
      description: "Export to pdf",
      name,
      extension: appState.exportEmbedScene ? "imago.pdf" : "pdf",
      fileHandle,
    });
  }
  else if (type === "clipboard") {
    try {
      const blob = canvasToBlob(tempCanvas);
      await copyBlobToClipboardAsPng(blob);
    } catch (error: any) {
      if (error.name === "CANVAS_POSSIBLY_TOO_BIG") {
        throw error;
      }
      throw new Error(t("alerts.couldNotCopyToClipboard"));
    } finally {
      tempCanvas.remove();
    }
  } else {
    tempCanvas.remove();
    // shouldn't happen
    throw new Error("Unsupported export type");
  }
};



export const getPageData = async () => {
  const pageList = getPageListFromStorage()
  let files = {};
  for (let index = 0; index < pageList.length; index++) {
    const page = pageList[index];
    const allElements = await LocalData.pagesStorage.get(page.id);
    const fileIds = allElements?.reduce((acc, element) => {
      if (isInitializedImageElement(element)) {
        return acc.concat(element.fileId);
      }
      return acc;
    }, [] as FileId[]) || [];

    if (fileIds.length) {
      const { loadedFiles, erroredFiles } = await LocalData.fileStorage.getFiles(fileIds);
      if (loadedFiles.length) {
        const filesMap = loadedFiles.reduce((acc, fileData) => {
          acc.set(fileData.id, fileData);
          return acc;
        }, new Map());
        files = { ...files, ...Object.fromEntries(filesMap) };
      }
    }
  }
  return { pageList, files }
}


const sendFileToReactNative = (
  blob: Blob,
  options: Object,
) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    if (window.ReactNativeWebView) {
      const base64data = reader.result;
      const message = JSON.stringify({ options, fileContent: base64data });
      window.ReactNativeWebView.postMessage(message);
    }
  };
};



export const saveAsJSONPage = async (
  appState: AppState,
  setAppState: React.Component<any, AppState>["setState"]
) => {

  const { pageList, files } = await getPageData();
  const { blob, serialized } = await getBoardBlob({ pageList, appState, files })

  const fileName = generateFileName();
  if (!appState.saveName || appState.saveName === "") {
    setAppState({ saveName: fileName })
  }

  const options = {
    name: fileName,
    extension: "clb",
    description: "Imago file",
  }

  let fileHandle = null

  if (window.electron) {
    window.electron.saveFile(serialized, options);
  } else {
    fileHandle = await fileSave(blob, {
      name: fileName,
      extension: "clb",
      description: "Imago file",
      fileHandle: null,
    });
    if (window.ReactNativeWebView) {
      sendFileToReactNative(blob, options);
    }
  }
  return { fileHandle };

};



export const downFileFromCanvas = async (
  pageList: Page[],
  files: BinaryFiles,
  appState: AppState,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    name,
    toGoogleDrive,
    hasBoardType,
    hasPngType,
    hasSvgType,
    hasPdfType,
    shareToGoogleClassroom,
    courseId,
    callback
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name: string;
    toGoogleDrive?: boolean;
    hasBoardType?: boolean;
    hasPngType?: boolean;
    hasSvgType?: boolean;
    hasPdfType?: boolean;
    shareToGoogleClassroom?: boolean;
    courseId?: string;
    callback?: () => void
  },) => {
  try {
    const zip = new JSZip();
    const folder = zip.folder(name);

    const needUploadFiles: NeedUploadFile[] = []

    if (hasBoardType) {
      const { blob } = await getBoardBlob({ pageList, appState, files })
      folder && folder.file(`${name}.${EXPORT_DATA_TYPES.clb}`, blob);
      needUploadFiles.push({ file: blob, fileName: `${name}.${EXPORT_DATA_TYPES.clb}`, extension: EXPORT_DATA_TYPES.clb })
    }

    if (hasPdfType) {
      const { blob } = await getPdfBlob({ pageList, appState, exportBackground, exportPadding, viewBackgroundColor, name, files })
      folder && folder.file(`${name}.pdf`, blob);
      needUploadFiles.push({ file: blob, fileName: `${name}.pdf`, extension: "pdf" })
    }


    const GeImgPage = async (folderType: JSZip | null | undefined, type: string, files: BinaryFiles) => {
      const totalPage = pageList.length
      for (let index = 0; index < pageList.length; index++) {
        const page = pageList[index];
        const allElements = await LocalData.pagesStorage.get(page.id) ?? [];
        const elements = getNonDeletedElements(allElements);

        let bgColor = viewBackgroundColor
        if (page.backgroundColor && page.backgroundColor !== "") {
          bgColor = page.backgroundColor
        }

        const pageIndex = index + 1;
        const { blob } = await getImageBlob({
          elements,
          appState,
          exportBackground,
          exportPadding,
          viewBackgroundColor: bgColor,
          type,
          files
        })

        folderType?.file(`${name}-${pageIndex}-${totalPage}.${type}`, blob);
        needUploadFiles.push({ file: blob, fileName: `${name}-${pageIndex}-${totalPage}.${type}`, extension: type })
      }
    }

    if (hasPngType) {
      let type = "png"
      const folderType = folder?.folder(type)
      await GeImgPage(folderType, type, files)

    }

    if (hasSvgType) {
      let type = "svg"
      const folderType = folder?.folder(type)
      await GeImgPage(folderType, type, files)
    }

    zip.generateAsync({ type: "blob" }).then(async function (content) {



      if (shareToGoogleClassroom) {
        const rest = await uploadMultipleGoogleDrive(needUploadFiles, name)

        if (rest) {
          const ids = rest.map(item => item.id).join(',');
          let formData: FormData = new FormData();
          formData.append("courseId", courseId!);
          formData.append("dirveFileIds", ids);
          formData.append("title", name);
          formData.append("token", getItemWithExpiration("google_access_token"));
          await fetch("/api/v1/coursework", {
            method: 'POST',
            body: formData,
            headers: new Headers({ 'Authorization': 'Bearer ' + appState.userInfo?.authorization }),
          })
        }

      }



      if (toGoogleDrive) {
        const driveFile = await uploadGoogleDrive(content, `${name}.zip`, "zip");
      }


      if (toGoogleDrive || shareToGoogleClassroom) {
        alert('Upload success');
      } else {

        const options = {
          description: "Export to ZIP",
          name,
          extension: "zip",
        }

        if (window.electron) {
          const arrayBuffer = await blobToArrayBuffer(content);
          window.electron.saveFile(arrayBuffer, options);
          return null;
        } else {

          if (window.ReactNativeWebView) {
            sendImageToReactNative(
              content,
              options,
            );
            return null;
          } else {


            // await fileSave(content, {
            //   name: name,
            //   extension: "zip",
            //   description: "Zip file",
            //   fileHandle: null,
            // });


            FileSaver.saveAs(content, `${name}.zip`);

            // return await fileSave(
            //   new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
            //   {
            //     description: "Export to SVG",
            //     name,
            //     extension: appState.exportEmbedScene ? "imago.svg" : "svg",
            //     fileHandle,
            //   },
            // );
          }
        }

      }

      callback && callback()

    })
  } catch (error) {
    console.log(error)

    callback && callback()
    alert("file save failure");
  } finally {

  }
}

interface PageMap {
  [key: string]: any;
}

export interface NeedUploadFile {
  file: Blob,
  fileName: string,
  extension: string,
}

const getBoardBlob = async ({
  pageList,
  files,
  appState
}: {
  pageList: Page[];
  files: BinaryFiles;
  appState: AppState
}) => {
  const pageMap: PageMap = {};
  for (let index = 0; index < pageList.length; index++) {
    const page = pageList[index];
    const allElements = await LocalData.pagesStorage.get(page.id) ?? [];
    const elements = getNonDeletedElements(allElements);
    const dataState = getExportedDataState(elements, appState, files, "local");
    pageMap[page.id] = dataState
  }
  const serialized = serializeAsJSONPage(pageMap)
  const blob = new Blob([serialized], {
    type: MIME_TYPES.clb,
  });
  return { blob, serialized }

}


const getPdfBlob = async ({
  pageList,
  files,
  appState,
  exportBackground,
  exportPadding,
  viewBackgroundColor,
  name
}: {
  pageList: Page[];
  files: BinaryFiles;
  appState: AppState;
  exportBackground: boolean;
  exportPadding?: number;
  viewBackgroundColor: string;
  name: string;
}) => {
  const pdf = new jsPDF();
  pdf.setProperties({
    title: name,
  });

  const promises = [];
  for (let index = 0; index < pageList.length; index++) {
    const page = pageList[index];
    const allElements = await LocalData.pagesStorage.get(page.id) ?? [];
    const elements = getNonDeletedElements(allElements);
    let bgColor = viewBackgroundColor
    if (page.backgroundColor && page.backgroundColor !== "") {
      bgColor = page.backgroundColor
    }
    const tempCanvas = await exportToCanvas(elements, appState, files, {
      exportBackground,
      viewBackgroundColor: bgColor,
      exportPadding,
    });
    tempCanvas.style.display = "none";

    const canvasDataUrl = tempCanvas.toDataURL('image/png');
    const img = new Image();
    img.src = canvasDataUrl;
    const promise = new Promise<void>((resolve) => {
      img.onload = async function () {
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = img.width;
        const imgHeight = img.height;
        let scale = 1;
        if (imgWidth > pageWidth || imgHeight > pageHeight) {
          const widthScale = pageWidth / imgWidth;
          const heightScale = pageHeight / imgHeight;
          scale = Math.min(widthScale, heightScale);
        } else {
          const widthScale = imgWidth / pageWidth;
          const heightScale = imgHeight / pageHeight;
          scale = Math.min(widthScale, heightScale);
        }
        const scaledWidth = imgWidth * scale;
        const scaledHeight = imgHeight * scale;
        const x = (pageWidth - scaledWidth) / 2;
        const y = (pageHeight - scaledHeight) / 2;
        pdf.addImage(img, "png", x, y, scaledWidth, scaledHeight);
        if (index != pageList.length - 1) {
          pdf.addPage();
        }
        resolve();
      };

    });
    promises.push(promise);

  }
  await Promise.all(promises);
  const blob = await pdf.output("blob");
  return { blob }
}



export const getImageBlob = async ({
  elements,
  appState,
  exportBackground,
  exportPadding,
  viewBackgroundColor,
  type,
  files
}: {
  elements: readonly NonDeletedImagoElement[];
  appState: AppState;
  exportBackground: boolean;
  exportPadding?: number;
  viewBackgroundColor: string;
  type: string;
  files: BinaryFiles;
}) => {

  if (type === "png") {
    const tempCanvas = await exportToCanvas(elements, appState, files, {
      exportBackground,
      viewBackgroundColor,
      exportPadding,
    });
    tempCanvas.style.display = "none";
    document.body.appendChild(tempCanvas);
    let blob = await canvasToBlob(tempCanvas);
    tempCanvas.remove();
    if (appState.exportEmbedScene) {
      blob = await (
        await import(/* webpackChunkName: "image" */ "./image")
      ).encodePngMetadata({
        blob,
        metadata: serializeAsJSON(elements, appState, files, "local"),
      });
    }

    return { blob }
  } else if (type === "svg") {
    const tempSvg = await exportToSvg(
      elements,
      {
        exportBackground,
        exportWithDarkMode: appState.exportWithDarkMode,
        viewBackgroundColor,
        exportPadding,
        exportScale: appState.exportScale,
        exportEmbedScene: appState.exportEmbedScene && type === "svg",
      },
      files,
    );
    let blob = new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg })
    return { blob }

  }
  return { blob: new Blob() }
}



export const exportCanvasNew = async (
  type: Omit<ExportType, "backend">,
  elements: readonly NonDeletedImagoElement[],
  appState: AppState,
  files: BinaryFiles,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    name,
    fileHandle = null,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name: string;
    fileHandle?: FileSystemHandle | null;
  },
) => {

  if (elements.length === 0) {
    throw new Error(t("alerts.cannotExportEmptyCanvas"));
  }
  if (type === "svg" || type === "clipboard-svg") {
    const tempSvg = await exportToSvg(
      elements,
      {
        exportBackground,
        exportWithDarkMode: appState.exportWithDarkMode,
        viewBackgroundColor,
        exportPadding,
        exportScale: appState.exportScale,
        exportEmbedScene: appState.exportEmbedScene && type === "svg",
      },
      files,
    );
    if (type === "svg") {
      const options = {
        description: "Export to SVG",
        name,
        extension: appState.exportEmbedScene ? "imago.svg" : "svg",
        fileHandle,
      }

      if (window.electron) {
        const arrayBuffer = await blobToArrayBuffer(new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }));
        window.electron.saveFile(arrayBuffer, options);
        return null;
      } else {

        if (window.ReactNativeWebView) {
          sendImageToReactNative(
            new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
            options,
          );
          return null;
        } else {
          return await fileSave(
            new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
            {
              description: "Export to SVG",
              name,
              extension: appState.exportEmbedScene ? "imago.svg" : "svg",
              fileHandle,
            },
          );
        }
      }

    } else if (type === "clipboard-svg") {
      await copyTextToSystemClipboard(tempSvg.outerHTML);
      return;
    }
  }

  const tempCanvas = await exportToCanvas(elements, appState, files, {
    exportBackground,
    viewBackgroundColor,
    exportPadding,
  });
  tempCanvas.style.display = "none";
  document.body.appendChild(tempCanvas);

  if (type === "png") {
    let blob = await canvasToBlob(tempCanvas);
    tempCanvas.remove();
    if (appState.exportEmbedScene) {
      blob = await (
        await import(/* webpackChunkName: "image" */ "./image")
      ).encodePngMetadata({
        blob,
        metadata: serializeAsJSON(elements, appState, files, "local"),
      });
    }

    const options = {
      description: "Export to PNG",
      name,
      extension: appState.exportEmbedScene ? "imago.png" : "png",
      fileHandle,
    }

    if (window.electron) {
      const arrayBuffer = await blobToArrayBuffer(blob);
      window.electron.saveFile(arrayBuffer, options);
      return null;
    } else {

      if (window.ReactNativeWebView) {
        sendImageToReactNative(blob, options);
        return null;
      } else {
        return await fileSave(blob, {
          description: "Export to PNG",
          name,
          extension: appState.exportEmbedScene ? "imago.png" : "png",
          fileHandle,
        });
      }


    }


  } else if (type === "pdf") {

    const pdf = new jsPDF();
    const canvasDataUrl = tempCanvas.toDataURL("image/jpeg");
    pdf.addImage(canvasDataUrl, "JPEG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

    pdf.setProperties({
      title: name,
    });

    const options = {
      description: "Export to pdf",
      name,
      extension: appState.exportEmbedScene ? "imago.pdf" : "pdf",
      fileHandle,
    }

    const blob = await pdf.output("blob");
    return await fileSave(blob, {
      description: "Export to pdf",
      name,
      extension: appState.exportEmbedScene ? "imago.pdf" : "pdf",
      fileHandle,
    });
  }
  else if (type === "clipboard") {
    try {
      const blob = canvasToBlob(tempCanvas);
      await copyBlobToClipboardAsPng(blob);
    } catch (error: any) {
      if (error.name === "CANVAS_POSSIBLY_TOO_BIG") {
        throw error;
      }
      throw new Error(t("alerts.couldNotCopyToClipboard"));
    } finally {
      tempCanvas.remove();
    }
  } else {
    tempCanvas.remove();
    // shouldn't happen
    throw new Error("Unsupported export type");
  }
};
