import React, { useEffect, useState } from "react"
import "./SchoolCategory.scss"
import { Button, Flex, Input, Card, List } from 'antd';
import Meta from "antd/es/card/Meta"
import { AppState } from "../types";
import { checkLangKey, t } from "../i18n";
import { LocalData } from "../imago-app/data/LocalData";


export const getSchoolCategory = (folder: any): string => {
  let key = folder.name.toLowerCase().replace("-", "_");
  const cat_key = `data_record.schoolCategory.${key}`;
  if (checkLangKey(cat_key)) {
    const v = t(cat_key);
    if (v != "") {
      return v;
    }
    return folder.name;
  }
  return folder.name;
};

export const SchoolCategory: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [folders, setFolders] = useState<{ name: string; imageUrl: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const host = "";

  useEffect(() => {
    (async () => {

      const folders = await LocalData.imagoSchoolStorage.getFolders()

      if (folders.length == 0) {
        setLoading(true)
        const fetchData = async () => {
          try {
            await fetch(`${host}/schoolapi/v1/school`).then(async (res) => {

              const rawXMLResponse = await res.json();
              setLoading(false)
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(rawXMLResponse, 'application/xml');

              // Extract the folder names from the <d:href> elements
              const hrefElements = xmlDoc.getElementsByTagName('d:href');
              const folderData = Array.from(hrefElements)
                .map(href => {
                  const path = href.textContent;
                  if (path) {
                    const name = path.split('/').filter(Boolean).pop() || '';
                    let imageUrl = '';
                    if (name === 'Pre-School') {
                      imageUrl = '/pre-school.png';
                    } else if (name === 'Primary') {
                      imageUrl = '/primary.png';
                    } else if (name === 'Secondary') {
                      imageUrl = '/secondary.png';
                    }
                    return { name, imageUrl };
                  }
                  return null;
                })
                .filter((folder): folder is { name: string; imageUrl: string } => folder !== null);
              LocalData.imagoSchoolStorage.saveFolders(folderData)
              setFolders(folderData);

            }).catch(() => {
              setLoading(false)
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

      } else {
        setFolders(folders);
      }



    })();
  }, []);


  const handleCategoryClick = async (name: string) => {
    try {

      localStorage.setItem('selectedCategory', name);

      setAppState({
        openDialog: "schoolLevel",
        openSidebar: null,
        lastOpenSidebar: "marketPlace"
      });
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };


  return (
    <div className="school-content">
      <h2>{t("labels.imago_school_choose_category")}</h2>
      <div className="list">

        {loading ? (<div className="loading"></div>) : (<List
          grid={{
            gutter: 20,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3
          }}
          dataSource={folders.slice(1)}
          renderItem={(folder) => (
            <Card
              hoverable
              key={folder.name}
              style={{
                margin: "30px",
                width: "140px",
                height: "180px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              cover={
                <div className="card-cover">
                  <img
                    alt={folder.name}
                    src={folder.imageUrl}
                    width="80"
                    height="80"
                  />
                </div>
              }

              onClick={() => {
                handleCategoryClick(folder.name);
              }}
            >

              <Meta
                title={
                  <div
                    style={{
                      textAlign: "center",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "16px",
                    }}
                  >
                    {getSchoolCategory(folder)}
                  </div>
                }
              />
            </Card>
          )}
        />)}


      </div>
    </div>
  );

};
