/**
 * This file deals with saving data state (appState, elements, images, ...)
 * locally to the browser.
 *
 * Notes:
 *
 * - DataState refers to full state of the app: appState, elements, images,
 *   though some state is saved separately (collab username, library) for one
 *   reason or another. We also save different data to different sotrage
 *   (localStorage, indexedDB).
 */

import {
  createStore,
  entries,
  del,
  getMany,
  set,
  setMany,
  clear,
  get,
  delMany,
  keys,
  values,
} from "idb-keyval";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { clearElementsForLocalStorage } from "../../element";
import { ImagoElement, FileId } from "../../element/types";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  HomeState,
  LibraryItem,
} from "../../types";
import { debounce } from "../../utils";
import { SAVE_TO_LOCAL_STORAGE_TIMEOUT, STORAGE_KEYS } from "../app_constants";
import { FileManager } from "./FileManager";
import {
  clearAllStorageData,
  getCurrPageFromStorage,
  getPageListFromStorage,
} from "./localStorage";
import { Locker } from "./Locker";
import { updateBrowserStateVersion } from "./tabSync";
import { PageMap } from "../collab/Collab";
import _ from "lodash";

const dataStateStore = createStore(
  "collab-data-state-db",
  "collab-data-state-store",
);
const filesStore = createStore("files-db", "files-store");
const pagesStore = createStore("pages-db", "pages-store");
const librariesStore = createStore("libraries-db", "libraries-store");
let personalBoardStore = createStore("personal-board-db", "data-store");
let stemStore = createStore("stem-db", "data-store");
let imagoSchoolStore = createStore("imago-school-db", "data-store");

class LocalFileManager extends FileManager {
  clearObsoleteFiles = async (opts: {
    currentFileIds: FileId[];
    clearAnyway?: boolean;
  }) => {
    await entries(filesStore).then((entries) => {
      for (const [id, imageData] of entries as [FileId, BinaryFileData][]) {
        // if image is unused (not on canvas) & is older than 1 day, delete it
        // from storage. We check `lastRetrieved` we care about the last time
        // the image was used (loaded on canvas), not when it was initially
        // created.
        if (
          (!imageData.lastRetrieved ||
            Date.now() - imageData.lastRetrieved > 24 * 3600 * 1000 ||
            opts.clearAnyway) &&
          !opts.currentFileIds.includes(id as FileId)
        ) {
          del(id, filesStore);
        }
      }
    });
  };
}

const saveDataStateToLocalStorage = (
  elements: readonly ImagoElement[],
  appState: AppState,
) => {
  try {
    // localStorage.setItem(
    //   STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
    //   //getCurrPageFromStorage(),
    //   JSON.stringify(clearElementsForLocalStorage(elements)),
    // );
    // localStorage.setItem(
    //   STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
    //   JSON.stringify(clearAppStateForLocalStorage(appState)),
    // );

    updateBrowserStateVersion(STORAGE_KEYS.VERSION_DATA_STATE);
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};


type SavingLockTypes = "collaboration";

export class LocalData {
  private static _save = debounce(
    async (
      elements: readonly ImagoElement[],
      appState: AppState,
      files: BinaryFiles,
      onFilesSaved: () => void,
    ) => {
      saveDataStateToLocalStorage(elements, appState);
      await this.dataStateStorage.save(
        STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
        clearElementsForLocalStorage(elements),
      );
      await this.dataStateStorage.save(
        STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
        clearAppStateForLocalStorage(appState),
      );
      await this.pagesStorage.save(getCurrPageFromStorage(), elements);

      await this.fileStorage.saveFiles({
        elements,
        files,
      });
      onFilesSaved();
    },
    SAVE_TO_LOCAL_STORAGE_TIMEOUT,
  );

  /** Saves DataState, including files. Bails if saving is paused */
  static save = (
    elements: readonly ImagoElement[],
    appState: AppState,
    files: BinaryFiles,
    onFilesSaved: () => void,
  ) => {
    // we need to make the `isSavePaused` check synchronously (undebounced)
    // if (!this.isSavePaused()) {   

    this._save(elements, appState, files, onFilesSaved);
    // }
  };

  static flushSave = () => {
    this._save.flush();
  };

  private static locker = new Locker<SavingLockTypes>();

  static pauseSave = (lockType: SavingLockTypes) => {
    this.locker.lock(lockType);
  };

  static resumeSave = (lockType: SavingLockTypes) => {
    this.locker.unlock(lockType);
  };

  static isSavePaused = () => {
    return document.hidden || this.locker.isLocked();
  };

  // ---------------------------------------------------------------------------

  static fileStorage = new LocalFileManager({
    getFiles(ids) {
      return getMany(ids, filesStore).then(
        async (filesData: (BinaryFileData | undefined)[]) => {
          const loadedFiles: BinaryFileData[] = [];
          const erroredFiles = new Map<FileId, true>();

          const filesToSave: [FileId, BinaryFileData][] = [];

          filesData.forEach((data, index) => {
            const id = ids[index];
            if (data) {
              const _data: BinaryFileData = {
                ...data,
                lastRetrieved: Date.now(),
              };
              filesToSave.push([id, _data]);
              loadedFiles.push(_data);
            } else {
              erroredFiles.set(id, true);
            }
          });

          try {
            // save loaded files back to storage with updated `lastRetrieved`
            setMany(filesToSave, filesStore);
          } catch (error) {
            console.warn(error);
          }

          return { loadedFiles, erroredFiles };
        },
      );
    },
    async saveFiles({ addedFiles }) {
      const savedFiles = new Map<FileId, true>();
      const erroredFiles = new Map<FileId, true>();

      // before we use `storage` event synchronization, let's update the flag
      // optimistically. Hopefully nothing fails, and an IDB read executed
      // before an IDB write finishes will read the latest value.
      updateBrowserStateVersion(STORAGE_KEYS.VERSION_FILES);

      await Promise.all(
        [...addedFiles].map(async ([id, fileData]) => {
          try {
            await set(id, fileData, filesStore);
            savedFiles.set(id, true);
          } catch (error: any) {
            console.error(error);
            erroredFiles.set(id, true);
          }
        }),
      );

      return { savedFiles, erroredFiles };
    },
  });

  static pagesStorage = {
    async save(pagination: string, elements: readonly ImagoElement[]) {
      await set(pagination, elements, pagesStore);
    },
    async saveAll(elements: PageMap) {
      return Promise.all(
        Object.keys(elements).map(async (k) => {
          await set(k, elements[k], pagesStore);
        }),
      );
    },
    async get(pagination: string): Promise<ImagoElement[]> {
      return (await get(pagination, pagesStore)) ?? [];
    },
    async getAll(): Promise<PageMap | undefined> {
      const all = await entries(pagesStore);
      const pageMap: PageMap = {};
      all.forEach((v) => {
        pageMap[v[0].toString()] = v[1];
      });
      return pageMap;
    },
    async remove(pagination: string) {
      await del(pagination, pagesStore);
    },
    async clear() {
      await clear(pagesStore);
    },
  };

  static clearPages = async () => {
    clearAllStorageData();
    await clear(pagesStore);
  };

  static librariesStorage = {
    async save(library: LibraryItem) {
      await set(library.id, library, librariesStore);
    },
    async saveAll(libraries: LibraryItem[]) {
      return Promise.all(
        libraries.map(async (library) => {
          await set(library.id, library, librariesStore);
        }),
      );
    },
    async remove(id: string) {
      await del(id, librariesStore);
    },
    async getList(skip: number, limit: number): Promise<LibraryItem[]> {
      // const ids = await keys(librariesStore);
      // const limitedIds = ids.slice(skip,skip+limit);
      const all = await values<LibraryItem>(librariesStore);

      const orderedLibs = _.orderBy(all, ["created"], ["desc"]);
      return orderedLibs.slice(skip, skip + limit);
      //return await getMany(limitedIds,librariesStore);
    },
    async clear() {
      await clear(librariesStore);
    },
    async total() {
      const ids = await keys(librariesStore);
      return ids.length;
    },
    async getAll() {
      return await values<LibraryItem>(librariesStore);
    },
  };

  static dataStateStorage = {
    async save(key: string, data: any) {
      await set(key, data, dataStateStore);
    },
    async get(key: string): Promise<any> {
      return (await get(key, dataStateStore)) ?? [];
    },
    async remove(key: string) {
      await del(key, dataStateStore);
    },
    async clear() {
      await clear(dataStateStore);
    },
  };


  static personalBoardStorage = {
    async save(memberId: string, elements: readonly ImagoElement[]) {
      await set(memberId, elements, personalBoardStore);
    },
    async saveBoardSize(memberId: string, width: number, height: number) {
      await set("boardSize_" + memberId, [width, height], personalBoardStore);
    },
    async get(memberId: string): Promise<ImagoElement[]> {
      return (await get(memberId, personalBoardStore)) ?? [];
    },
    async getBoardSize(memberId: string): Promise<number[]> {
      return (await get("boardSize_" + memberId, personalBoardStore)) ?? [];
    },
    async remove(memberId: string) {
      await del(memberId, personalBoardStore);
    },
    async clear() {
      await clear(personalBoardStore);
    },
  };


  static stemStorage = {
    async saveSubjects(subjects: any[]) {
      await set("subjects", subjects, stemStore);
    },
    async getSubjects(): Promise<any[]> {
      return (await get("subjects", stemStore)) ?? [];
    },

    async saveCatetories(category: any[]) {
      await set("categories", category, stemStore);
    },
    async getCategories(): Promise<any[]> {
      return (await get("categories", stemStore)) ?? [];
    },


    async saveGrades(grades: any[]) {
      await set("grades", grades, stemStore);
    },
    async getGrades(): Promise<any[]> {
      return (await get("grades", stemStore)) ?? [];
    },

    async saveSimulation(subjectId: string, simulation: any[]) {
      await set("sub_" + subjectId, simulation, stemStore);
    },
    async getSimulation(subjectId: string): Promise<any[]> {
      return (await get("sub_" + subjectId, stemStore)) ?? [];
    },

    async saveAllSimulation(simulation: any[]) {
      await set("all_", simulation, stemStore);
    },
    async getAllSimulation(): Promise<any[]> {
      return (await get("all_", stemStore)) ?? [];
    },

    async removeSubjects() {
      await del("subjects", stemStore);
    },
    async clear() {
      await clear(stemStore);
    },
  };

  static imagoSchoolStorage = {
    async saveFolders(category: any[]) {
      await set("folders", category, imagoSchoolStore);
    },
    async getFolders(): Promise<any[]> {
      return (await get("folders", imagoSchoolStore)) ?? [];
    },

    async saveCategoryLevels(currCategory: string, list: any[]) {
      await set(currCategory, list, imagoSchoolStore);
    },
    async getCategoryLevels(currCategory: string): Promise<any[]> {
      return (await get(currCategory, imagoSchoolStore)) ?? [];
    },

    async saveStandardSubjects(catetory: string, level: string, list: any[]) {
      let key = "subject_" + catetory + "_" + level;
      await set(key, list, imagoSchoolStore);
    },
    async getStandardSubjects(catetory: string, level: string): Promise<any[]> {
      let key = "subject_" + catetory + "_" + level;
      return (await get(key, imagoSchoolStore)) ?? [];
    },

    async saveSubjectBooks(catetory: string, level: string, subject: string, list: any[]) {
      let key = "book_" + catetory + "_" + level + "_" + subject;
      await set(key, list, imagoSchoolStore);
    },
    async getSubjectBooks(catetory: string, level: string, subject: string): Promise<any[]> {
      let key = "book_" + catetory + "_" + level + "_" + subject;
      return (await get(key, imagoSchoolStore)) ?? [];
    },

    async clear() {
      await clear(imagoSchoolStore);
    },
  };

}
