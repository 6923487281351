import "./AiChat.scss";
import React, { useEffect, useState } from "react";
import { Message, Ollama } from "ollama/dist/browser";
//import ollama from 'ollama/dist/browser';
import { marked } from "marked";
import { Button, Input, List, Space, message, notification } from "antd";
import {
  AiSearchIcon,
  CloseIcon,
  DuplicateIcon,
  IconClose,
  IconCloseWhite,
  IconCopy,
  IconUnion,
  PencilIcon,
  ProfileIcon,
  ResetIcon,
  TranslateNoColourIcon,
  SendIcon
} from "./icons";
import { KEYS } from "../keys";
import { debounce, delay, invert, reverse, throttle } from "lodash";
import { nanoid } from "nanoid";
import { AppState, StickyNote } from "../types";
import { t } from "../i18n";
import Spinner from "./Spinner";
import { DraggablePanel } from "./DraggablePanel";
import { IconSend, IconTrash, IconZoomOut } from "./newIcons";
import { StickyNoteBackground } from "./StickyNotePanel";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import { Popover } from "antd";
import { TranslateAIPopupChat } from "./TranslateAIPopup";
import { AiChatTranslateWin } from "./AiChatTranslateWin";

export const AiChat: React.FC<{
  appState: AppState;
  setAppState?: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [messages, setMessages] = useState<
    (Message & { id: string; createdAt: Date, translateContent: string; })[]
  >([]);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [loading, setLoading] = useState(false);
  const [requestAI, setRequestAI] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [translateText, setTranslatedText] = useState("");
  const [isActive, setActive] = React.useState(false);
  // const [stickyNotes,setStickyNotes] = useState<any[]>([])
  const chatContentRef = React.useRef<HTMLDivElement>(null);
  const renderer = new marked.Renderer();
  const ollama = new Ollama({
    fetch: (url, init) =>
      fetch("/api/v1/ai/chat", {
        ...init,
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
        }),
      }),
  });

  // For code blocks with simple backticks
  renderer.codespan = (code) => {
    return `<code>${code.replaceAll("&amp;", "&")}</code>`;
  };
  useEffect(() => {
    chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
  });

  async function aiChat(): Promise<void> {
    if (!appState.userLicence?.aIText) {
      alert(t("alerts.shouldGrade"));
      return;
    }
    setRequestAI(true)
  }

  useEffect(() => {
    if (prompt != '') {
      (async () => {
        const assistantMsgId = nanoid();
        let newMessages = [
          ...messages,
          {
            id: nanoid(),
            role: "user",
            content: prompt,
            translateContent: '',
            createdAt: new Date()
          },
          {
            id: assistantMsgId,
            role: "assistant",
            content: "",
            translateContent: '',
            createdAt: new Date(),
          },
        ];
        setPrompt("");
        setRequestAI(false)
        setMessages(newMessages);
        setLoading(true);

        ollama
          .chat({
            model: "llama3",
            messages: newMessages,
            stream: true,
          })
          .then(async (stream) => {
            setLoading(false);
            const content = "";
            let i = 0;
            for await (const chunk of stream) {
              if ((chunk as any).code == 404) {
                const msg = (chunk as any).msg;
                chunk.message = { role: "assistant", content: msg };
                alert(msg);
              }
              // console.log(chunk)
              delay(() => {
                newMessages = newMessages.map((m) => {
                  if (m.id == assistantMsgId) {
                    if (chunk.message.content != undefined) {
                      m.content += chunk.message.content;
                    }
                  }

                  return m;
                });
                setMessages(newMessages);
              }, 50 * i);
              // setMessages(newMessages);
              // delay(() => {
              //     content+=chunk.message.content;
              //     document.getElementById(assistantMsgId)!.innerHTML =  marked.parse(content) as string;
              //     chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
              // }, 50*i);
              i++;
            }

            // setMessages(newMessages);
          });

      })();
    }

  }, [requestAI]);

  const removeMessage = (id: string) => {
    setMessages(messages.filter((s) => s.id != id));
  };

  const handleOnClick = (message: Message) => {
    setActive(!isActive);
    //translateText
  };

  return (
    <div className="ai-chat">
      <div className="body" ref={chatContentRef}>
        <List
          itemLayout="vertical"
          dataSource={messages}
          size="small"
          split={false}
          renderItem={(item, i) => (
            <List.Item key={i}>
              <>
                {/* <List.Item.Meta                    
                    avatar={ProfileIcon} 
                    title={item.role=="user"?"YOU":"IMAGOAI"}
                    description={new Date(item.createdAt).toLocaleString()}
                    /> */}
                <div>
                  <b>{item.role == "user" ? "YOU" : "IMAGO AI"}</b>
                </div>
                {!item.content ? (
                  <div style={{ height: 40 }}>
                    <Spinner />
                  </div> //<div id={item.id} dangerouslySetInnerHTML={{__html:marked.parse(item.content,{gfm: true,breaks: true,renderer}) as string}}></div>
                ) : (
                  <div id={item.id}>
                    {/* {!item.content && <div id="" style={{minHeight:40}}><Spinner /></div>}
                            {item.role=="user"?item.content:""} */}
                    {marked.lexer(item.content).map((t) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked.parse(t.raw, {
                              ...marked.getDefaults(),
                              gfm: true,
                              breaks: true,
                              renderer,
                            }) as string,
                          }}
                        ></div>
                      );
                    })}
                  </div>
                )}
                {(messages[i].translateContent && messages[i].translateContent != '') && (<div id={`translate_${item.id}`} style={{ padding: "5px 0", fontStyle: "italic" }}>

                  {marked.lexer(messages[i].translateContent).map((t) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked.parse(t.raw, {
                            ...marked.getDefaults(),
                            gfm: true,
                            breaks: true,
                            renderer,
                          }) as string,
                        }}
                      ></div>
                    );
                  })}

                </div>)}

                <div className="item-actions">
                  <a
                    href={undefined}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(item.content)
                        .then(() => {
                          console.log("Text copied to clipboard");
                          message.info("Text copied to clipboard");
                        })
                        .catch((err) => {
                          console.error("Failed to copy text:", err);
                          message.error("Failed to copy text");
                        });
                    }}
                  >
                    {IconCopy}
                  </a>

                  {item.role !== "user" && (
                    <a
                      href={undefined}
                      onClick={() => {

                        let userContent = messages[i - 1].content
                        let AIContent = item.content;

                        if (window.AndroidInterface) {

                          if (messages[i - 1].translateContent && messages[i - 1].translateContent != '') {
                            userContent += "\n\n" + messages[i - 1].translateContent
                          }

                          if (item.translateContent && item.translateContent != '') {
                            AIContent += "\n\n" + item.translateContent
                          }

                          const message = JSON.stringify({
                            event: "stickyNotes",
                            user: { content: userContent },
                            assistant: { content: AIContent },
                          });
                          window.AndroidInterface.sendMessage(message);
                        } else if (setAppState) {
                          console.log([messages[i - 1], item]);
                          // setStickyNotes([...stickyNotes,{user:messages[i-1],assistant:item}]);
                          const randomBackground = Math.floor(
                            (Math.random() * 10) / 2,
                          );
                          let stickyContent = marked
                            .lexer(item.content)
                            .map((t) => {
                              return marked.parse(t.raw, {
                                ...marked.getDefaults(),
                                gfm: true,
                                breaks: true,
                                renderer,
                              }) as string;
                            })
                            .join("");

                          if (messages[i - 1].translateContent && messages[i - 1].translateContent != '') {
                            userContent += "<br>" + messages[i - 1].translateContent
                          }

                          if (item.translateContent && item.translateContent != '') {
                            stickyContent += "<br>" + marked
                              .lexer(item.translateContent)
                              .map((t) => {
                                return marked.parse(t.raw, {
                                  ...marked.getDefaults(),
                                  gfm: true,
                                  breaks: true,
                                  renderer,
                                }) as string;
                              })
                              .join("");
                          }

                          const stickyNote: StickyNote = {
                            id: nanoid(),
                            key: nanoid(),
                            status: "expand",
                            content: `<h4>YOU:</h4><p>${userContent}</p><h4>AI:</h4><p>${stickyContent}</p>`,
                            background:
                              StickyNoteBackground[randomBackground],
                            fontStyle: "normal",
                            textDecoration: "none",
                            fontWeight: "normal",
                            position: null,
                          }
                          collabAPI?.addStickyNote(stickyNote)
                        }
                      }}
                    >
                      {IconUnion}
                    </a>
                  )}

                  <a
                    href={undefined}
                    onClick={() => {
                      removeMessage(item.id);
                    }}
                  >
                    {IconClose}
                  </a>

                  <AiChatTranslateWin translateResult={(translatedText) => {
                    messages[i].translateContent = translatedText
                    handleOnClick(item)

                  }} appState={appState} setAppState={setAppState} textValue={messages[i].content} />

                  {(messages[i].translateContent && messages[i].translateContent != '') && item.role === "user" && (
                    <a
                      style={{ paddingTop: "3px" }}
                      href={undefined}
                      onClick={() => {
                        console.log(messages[i].translateContent)
                        setPrompt(messages[i].translateContent)
                        aiChat()
                      }}
                    >
                      {IconSend}
                    </a>
                  )}

                </div>
              </>
            </List.Item>
          )}
        />
      </div>

      <div className="header">
        <Input
          placeholder={t("winTabList.chat_with_ai")}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyDown={(e) => {
            e.key == KEYS.ENTER && aiChat();

          }}
        />
        <Button
          onClick={aiChat}
          icon={AiSearchIcon}
          style={{ background: "none" }}
        />
      </div>
    </div>
  );
};
