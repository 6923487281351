import React, { useEffect, useState } from "react"
import "./SchoolLevel.scss"
import { Table, Breadcrumb, Flex, Button } from 'antd';
import { AppState } from "../types";
import { checkLangKey, t } from "../i18n";
import { LocalData } from "../imago-app/data/LocalData";

export const getSchoolCategory = (selectedCategory: string): string => {
  let key = decodeURIComponent(selectedCategory).toLowerCase().replace("-", "_");
  const cat_key = `data_record.schoolCategory.${key}`;
  if (checkLangKey(cat_key)) {
    const v = t(cat_key);
    if (v != "") {
      return v;
    }
    return selectedCategory;
  }
  return selectedCategory;
};

export const getSchoolLevel = (level: any): string => {
  let key = decodeURIComponent(level.name).toLowerCase().replace(/ /g, "_");
  const level_key = `data_record.schoolLevel.${key}`;
  if (checkLangKey(level_key)) {
    const v = t(level_key);
    if (v !== "") {
      return v;
    }
    return decodeURIComponent(level.name);
  }
  return decodeURIComponent(level.name);
};

export const SchoolLevel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];

}> = ({ appState, setAppState }) => {
  const host = "";
  const [selectedLevels, setSelectedLevels] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [loading, setLoading] = useState(false);
  const formatSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = (bytes / Math.pow(1024, i)).toFixed(1);
    return `${size} ${sizes[i]}`;
  };

  const columns = [
    {
      title: t("labels.imago_school_name"),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <span className="hoverable-name">
            {getSchoolLevel(record)}
          </span>
        );
      },
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render: (type: string) => type || 'Unknown',
    // },
    {
      title: t("labels.imago_school_size"),
      dataIndex: 'size',
      key: 'size',
      render: (size: string | undefined) => {

        if (!size || size === '—') {
          return '—';
        }

        const sizeInBytes = parseInt(size, 10);

        if (isNaN(sizeInBytes)) {
          return '—';
        }

        return formatSize(sizeInBytes);
      },
    },
    {
      title: t("labels.imago_school_modified"),
      dataIndex: 'modified',
      key: 'modified',
      render: (modifiedDate: string) => {
        const date = new Date(modifiedDate);
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).format(date);
        return formattedDate;
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const selectedCategory = localStorage.getItem('selectedCategory');
        setSelectedCategory(selectedCategory)

        if (!selectedCategory) {
          console.warn("No selected category found in localStorage");
          return;
        }


        const currCategory = encodeURIComponent(selectedCategory);
        const levels = await LocalData.imagoSchoolStorage.getCategoryLevels(currCategory)

        if (levels.length == 0) {
          setLoading(true)
          fetch(`${host}/schoolapi/v1/school/${currCategory}`).then(async (res) => {
            const rawXMLResponse = await res.json();
            setLoading(false)
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(rawXMLResponse, 'application/xml');

            const hrefElements = xmlDoc.getElementsByTagName('d:href');
            const typeElements = xmlDoc.getElementsByTagName('d:resourcetype');
            const sizeElements = xmlDoc.getElementsByTagName('d:getcontentlength');
            const modifiedElements = xmlDoc.getElementsByTagName('d:getlastmodified');
            const contentTypeElements = xmlDoc.getElementsByTagName('d:getcontenttype');


            const folderData = Array.from(hrefElements).map((href, index) => {
              const path = href.textContent;
              if (path && index != 0) {
                const resourceType = typeElements[index]?.textContent;
                const contentType = contentTypeElements[index]?.textContent || 'Unknown'; // Default to 'Unknown'
                const sizeElement = sizeElements[index - 1];
                const size = sizeElement ? sizeElement.textContent : '—';
                const modified = modifiedElements[index]?.textContent || '—';

                const type = resourceType ? 'Folder' : contentType.split('/')[1] || 'Unknown';

                return {
                  name: path.split("/").filter(Boolean).pop() || "",
                  path: path,
                  type: type,
                  size: size,
                  modified: modified,
                };
              }
              return null;
            }).filter((folder) => folder !== null);
            LocalData.imagoSchoolStorage.saveCategoryLevels(currCategory, folderData);
            setSelectedLevels(folderData);
          }).catch(() => {
            setLoading(false)
          });

        } else {
          setSelectedLevels(levels);
        }

      } catch (error) {
        console.error("Error fetching or parsing the data:", error);
      }
    })();
  }, []);

  const handleRowClick = async (selectedLevel: any) => {

    try {

      const selectedCategory = localStorage.getItem('selectedCategory');

      if (!selectedCategory) {
        throw new Error("No category selected from the previous page.");
      }


      localStorage.setItem('selectedCategory', selectedCategory);
      localStorage.setItem('selectedLevel', selectedLevel);

      setAppState({
        openDialog: "schoolSubject",
        openSidebar: null,
        lastOpenSidebar: "marketPlace"
      });
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };


  // const getBreadcrumbNav = () => {
  //   const selectedCategory = decodeURIComponent(localStorage.getItem('selectedCategory') || 'Category');

  //   return [
  //       selectedCategory,
  //   ];
  // };

  // const breadcrumbItems = getBreadcrumbNav();

  const handleBack = () => {
    setAppState({
      openDialog: "school",
      openSidebar: null,
      lastOpenSidebar: "marketPlace"
    });
    localStorage.removeItem('selectedLevel');
  };

  return (
    <div className="school-folder">
      <div className="content">
        <div className="table-container">

          {/* <Breadcrumb style={{ margin: '16px 0' }}>
                {breadcrumbItems.map((item, index) => (
                    <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
            </Breadcrumb> */}


          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <Button onClick={handleBack}
              style={{ marginRight: '8px', fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', lineHeight: '1' }}>
              ←
            </Button>
            <h2 style={{ margin: 0 }}>{getSchoolCategory(selectedCategory)}</h2>
          </div>


          <div className="list">

            {loading ? (<div className="loading"></div>) : (<Table
              className="table"
              dataSource={selectedLevels}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}

              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record.name);
                },
              })}
            />)}


          </div>


        </div>
      </div>
    </div>
  );

};