import React, { useEffect, useState } from "react"
import "./StemSimulation.scss"
import { Card, List } from 'antd';
import Meta from "antd/es/card/Meta"
import { AppState, WebEmbed } from "../types";
import { checkLangKey, t } from "../i18n";
import { getSubjectName } from "./StemSubject"
import { API_URL, EmbedType } from "../constants";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import { LocalData } from "../imago-app/data/LocalData";


export const getCategoryName = (item: any): string => {
  let key = item.nameKey.replace("sim_ctg_", "");
  const category_key = `data_record.category.${key}`;
  if (checkLangKey(category_key)) {
    const v = t(category_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name;
};

export const getGradeName = (item: any): string => {
  let key = item.nameKey.replace("sim_info_grade_", "");
  const grade_key = `data_record.grade.${key}`;
  if (checkLangKey(grade_key)) {
    const v = t(grade_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name;
};

export const getSimulationName = (item: any): string => {
  let key = item.nameKey.replace("sim_info_", "");
  const sim_key = `data_record.simulation.${key}`;
  if (checkLangKey(sim_key)) {
    const v = t(sim_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name;
};


export const StemSimulation: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = React.memo(({ appState, setAppState }) => {
  // API data
  const [subjects, setSubjects] = useState<any[]>();
  const [categories, setCategories] = useState<any[]>();
  const [simulations, setSimutlations] = useState<any[]>([]);
  const [grades, setGrades] = useState<any[]>([]);
  const [type, setType] = useState<(keyof typeof EmbedType)>("stem")
  const host = "";

  const [collabAPI] = useAtom(collabAPIAtom);
  const [isGradeOpen, setIsGradeOpen] = useState(false);
  const [isSubjectOpen, setIsSubjectOpen] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Runtime
  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [previousSelectedGrades, setPreviousSelectedGrades] = useState<string[]>([]);
  const [sideMenuCheckboxes, setCheckboxes] = useState<boolean[]>([]);
  const [sideMenuGradeCheckboxes, setGradeCheckboxes] = useState<boolean[]>([]);
  const [selectedSimulations, setSelectedSimulations] = useState<any[]>([]);
  const [isAllUntickPreviously, setIsAllUntickPreviously] = useState<boolean>();
  const [isLoadingSimulation, setisLoadingSimulation] = useState<boolean>(false);

  useEffect(() => {
    (async () => {

      let selectedSubjectID: any = null;
      selectedSubjectID = localStorage.getItem('selectedSubjectID');
      const subjectSimulations = await LocalData.stemStorage.getSimulation(selectedSubjectID)

      if (subjectSimulations.length == 0) {
        setisLoadingSimulation(true)
        fetch(
          `${host}/stemapi/v1/simulations/:id/findSimulation?subjectID=${selectedSubjectID}`,
        ).then(async (res) => {
          let stemSimutlation = await res.json();
          if (stemSimutlation.status == "success") {
            setisLoadingSimulation(false)
          }
          setSelectedSimulations(stemSimutlation.data);
          await LocalData.stemStorage.saveSimulation(selectedSubjectID, stemSimutlation.data)
        }).catch((err) => {
          setisLoadingSimulation(false)
        })
      } else {
        setSelectedSimulations(subjectSimulations);
      }

      const subjects = await LocalData.stemStorage.getSubjects()
      if (subjects.length == 0) {
        const response = fetch(
          `${host}/stemapi/v1/subjects`
        ).then(async res => {
          let stemSubject = (await res.json());
          setSubjects(stemSubject.data);
          LocalData.stemStorage.saveSubjects(stemSubject.data)
        });
      } else {
        setSubjects(subjects);
      }


      const categories = await LocalData.stemStorage.getCategories()
      if (categories.length == 0) {

        fetch(
          `${host}/stemapi/v1/category`
        ).then(data => {
          return data.json();

        }).then(dataCategory => {
          let stemCategories = dataCategory;
          LocalData.stemStorage.saveCatetories(stemCategories.data)
          setCategories(stemCategories.data);
          const selectedSubject = localStorage.getItem('selectedSubject');
          if (selectedSubject) {
            setCheckboxes((prev) => {
              const updatedState = updateCheckboxesInitialize(selectedSubject, true, prev, stemCategories.data);
              return updatedState;
            });
          }
        });
      } else {
        setCategories(categories);
        const selectedSubject = localStorage.getItem('selectedSubject');
        if (selectedSubject) {
          setCheckboxes((prev) => {
            const updatedState = updateCheckboxesInitialize(selectedSubject, true, prev, categories);
            return updatedState;
          });
        }
      }

      const allSimulations = await LocalData.stemStorage.getAllSimulation()

      if (allSimulations.length == 0) {
        fetch(
          `${host}/stemapi/v1/simulations`,
        ).then((data) => {
          return data.json();
        }).then((simulationData) => {
          let stemSimutlation = simulationData;
          const selectedSubjectID = localStorage.getItem('selectedSubjectID');
          setSimutlations(stemSimutlation.data);
          LocalData.stemStorage.saveAllSimulation(stemSimutlation.data)
        });
      } else {
        setSimutlations(allSimulations);
      }



    })();
  }, []);


  useEffect(() => {
    (async () => {

      const grades = await LocalData.stemStorage.getGrades()
      if (grades.length == 0) {
        //grade
        const responseGrade = await fetch(
          `${host}/stemapi/v1/grade`
        );
        let stemGrade = (await responseGrade.json());
        setGrades(stemGrade.data);
        await LocalData.stemStorage.saveGrades(stemGrade)
      } else {
        setGrades(grades);
      }


    })();
  }, []);

  useEffect(() => {
    // Fetch simulations whenever selectedGrades changes
    fetchSimulations();
  }, [selectedSubject, selectedCategory, selectedGrades]);


  const addSimulation = async (simulationID: string, url: string) => {
    try {

      const webEmbed: WebEmbed = { url: url, id: simulationID, creatorUserId: appState.userInfo?.id, hide: false, type, zIndex: appState.webEmbed.filter(w => !w.removed).length + 1 }
      setAppState({
        openDialog: null,
        webEmbed: [
          ...appState.webEmbed,
          webEmbed,
        ],
      });
      collabAPI?.addRoomLink(webEmbed)
    } catch (error: any) { }
  };


  const updateCheckboxes = (
    name: string,
    checked: boolean,
    prevState: any) => {

    const newState = {
      ...prevState,
      [name]: checked
    };
    const updatedIDs: string[] = [];
    const tempActiveSubject: string[] = [];

    subjects?.forEach((subject) => {
      if (subject.name === name) {
        newState[name] = checked;
        updatedIDs.push(subject._id);
      }

      if (newState[subject.name]) {
        tempActiveSubject.push(subject._id);
      }
    });

    const tempActiveCategory: string[] = [];
    categories?.forEach((category) => {
      if (category.subjectName === name || category.name === name) {
        newState[category.name] = checked;
        updatedIDs.push(category._id);
      }

      if (newState[category.name]) {
        newState[category.subjectName] = true;
        tempActiveCategory.push(category._id);

        if (!tempActiveSubject.includes(category.subjectID)) {
          tempActiveSubject.push(category.subjectID)
        }
      }
    });

    // Handle the unticking of subjects if all their categories are unticked
    if (!checked) {
      // Get the subject related to the unticked category or subject
      const relatedSubject = subjects?.find(subject =>
        categories?.some(category =>
          (category.subjectName === subject.name && category.name === name) ||
          (subject.name === name)
        )
      );

      if (relatedSubject) {
        // Check if all categories of this subject are unticked
        const allCategoriesUnticked = categories
          ?.filter(category => category.subjectName === relatedSubject.name)
          .every(category => !newState[category.name]);

        updatedIDs.push(relatedSubject._id);

        if (allCategoriesUnticked) {
          newState[relatedSubject.name] = false;
          if (tempActiveSubject.includes(relatedSubject._id)) {
            tempActiveSubject?.filter(subjectID => subjectID != relatedSubject._id);
          }
        }
      }
    }

    if (tempActiveSubject.join() != selectedSubject.join()) {
      setSelectedSubject(tempActiveSubject)
    }

    const IsAllUnticked = Object.values(newState).every(value => !value);

    setSelectedSimulations((prevState) => filterSimulations(updatedIDs, checked, prevState, name, tempActiveSubject, tempActiveCategory, IsAllUnticked));

    return newState;
  };

  const filterSimulations = (
    selectedIDs: string[],
    activate: boolean,
    prevState: any[],
    categoryName: string,
    currentSelectedSubject: string[],
    currentSelectedCategory: string[],
    isAllUntick?: boolean,
  ) => {
    let tempFilteredSimulations: any[] = [...prevState];

    if (isAllUntick) {
      tempFilteredSimulations = [...(simulations || [])];
      setIsAllUntickPreviously(true);
    }
    else if (activate) {
      if (isAllUntickPreviously) {
        tempFilteredSimulations = [];
        setIsAllUntickPreviously(false);
      }

      let tempSelectedCategory = currentSelectedCategory;
      // Adding simulations related to the selected categories or subject
      simulations?.forEach((simulation) => {
        if (selectedIDs.includes(simulation.subjectID) ||
          selectedIDs.includes(simulation.categoryID)) {
          if (!tempFilteredSimulations.includes(simulation)) {
            tempFilteredSimulations.push(simulation);
          }
        }

        if (currentSelectedSubject.includes(simulation.subjectID) && simulation.categoryID == null) {
          if (!tempSelectedCategory.includes("null")) {
            tempSelectedCategory.push("null")
          }
        }
      });
      setSelectedCategory(tempSelectedCategory)
    } else {
      // Removing simulations only for the unticked category
      tempFilteredSimulations = tempFilteredSimulations.filter((item) => {
        return (item.categoryID != null && !selectedIDs.includes(item.categoryID))
          || !selectedIDs.includes(item.subjectID);
      });

      let hasNoNullCategory = true;
      tempFilteredSimulations.forEach(simulation => {
        if (simulation.categoryID == null) {
          hasNoNullCategory = false;
          return;
        }
      });

      if (hasNoNullCategory) {
        let tempSelectedCategory = currentSelectedCategory;
        if (tempSelectedCategory.includes("null")) {
          tempSelectedCategory = tempSelectedCategory.filter((item) => { return item !== "null" })
        }

        setSelectedCategory(tempSelectedCategory)
      }

      // Re-add simulations from other categories that are still checked
      categories?.forEach((category) => {
        if (category.name !== categoryName && prevState[category.name]) {
          simulations?.forEach((simulation) => {
            if (
              simulation.categoryID === category._id &&
              !tempFilteredSimulations.includes(simulation)
            ) {
              tempFilteredSimulations.push(simulation);
            }
          });
        }
      });
    }

    setSelectedSimulations(tempFilteredSimulations);
    return tempFilteredSimulations;
  };

  const updateCheckboxesInitialize = (name: string, checked: boolean, prevState: any, categories: any[]) => {
    const newState = {
      ...prevState,
      [name]: checked,
    };

    subjects?.forEach((subject) => {
      if (subject.name === name) {
        newState[name] = checked;
      }
    });

    let tempCategories: string[] = [];
    categories?.forEach((category) => {
      if (category.subjectName === name || category.name === name) {
        newState[category.name] = checked;
      }

      if (newState[category.name]) {
        tempCategories.push(category._id)
      }
    });

    setSelectedCategory(tempCategories)

    return newState;
  };


  const updateGradeCheckboxes = (name: string, checked: boolean) => {
    let updatedGrades = [...selectedGrades];

    grades.forEach(grade => {
      if (grade.name === name) {
        if (checked) {
          // If the grade is checked, add it to the list
          if (!updatedGrades.includes(grade._id)) {
            updatedGrades.push(grade._id);
          }
        }
        else {
          // If the grade is unchecked, remove it from the list
          updatedGrades = updatedGrades.filter(id => id !== grade._id);
        }
      }
    });

    setSelectedGrades(updatedGrades);
  };

  // Event handler function
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxes((prevState) => updateCheckboxes(name, checked, prevState));
  };

  const handleGradeCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Update selected grades
    await updateGradeCheckboxes(name, checked);

  };

  const fetchSimulations = async () => {
    let url = `${host}/stemapi/v1/simulations/:id/findSimulation?subjectID=&categoryID=&gradeID=`;

    if ((previousSelectedGrades.length == 0 && selectedGrades.length == 0)) {
      return;
    }

    if (selectedSubject.length === 0) {
      url = url.replace(/[\?&]subjectID=[^&]*/, '?subjectID=');
    } else {
      const subjectQuery = `?subjectID=${selectedSubject.join(',')}`;
      url = url.replace(/[\?&]subjectID=[^&]*/, subjectQuery);
    }

    if (selectedCategory.length === 0) {
      url = url.replace(/[\?&]categoryID=[^&]*/, '&categoryID=');
    } else {
      const categoryQuery = `&categoryID=${selectedCategory.join(',')}`;
      url = url.replace(/[\?&]categoryID=[^&]*/, categoryQuery);
    }

    if (selectedGrades.length === 0) {
      url = url.replace(/[\?&]gradeID=[^&]*/, '&gradeID=');
    } else {
      const gradeQuery = `&gradeID=${selectedGrades.join(',')}`;
      url = url.replace(/[\?&]gradeID=[^&]*/, gradeQuery);
    }


    try {
      const response = await fetch(url);
      const result = await response.json();

      if (result && Array.isArray(result.data)) {
        setSelectedSimulations(result.data);
      } else {
        if (selectedGrades.length === 0) {
          setSelectedSimulations(simulations)
        }
      }

      setPreviousSelectedGrades(selectedGrades)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleSubjectSection = () => {
    setIsSubjectOpen(!isSubjectOpen);
  };

  const toggleGradeSection = () => {
    setIsGradeOpen(!isGradeOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>

      <div className="stem-simulation-content">
        <div className="hamburger" onClick={toggleSidebar}> ☰ </div>

        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>

          <div className="checkbox-container">
            <div className="main-title" onClick={toggleSubjectSection}>
              {t("labels.stem_subject")} {isSubjectOpen ? "▼" : "▲"}
            </div>

            {isSubjectOpen && (
              <List
                grid={{ xs: 1, sm: 1, md: 1, column: 1 }}
                dataSource={subjects}
                renderItem={(subject) => (
                  <div key={subject.name}>
                    <label className="checkbox-title">
                      <input
                        type="checkbox"
                        name={subject.name}
                        checked={sideMenuCheckboxes[subject.name]}
                        onChange={handleCheckboxChange}
                      />
                      {getSubjectName(subject)}
                    </label>

                    {categories && categories.length > 0 &&
                      <List
                        grid={{ xs: 1, sm: 1, md: 1, column: 1 }}
                        dataSource={categories}
                        renderItem={(category: any) => (
                          category.subjectName == subject.name ? (
                            <div className="checkbox-category">
                              <label>
                                <input
                                  type="checkbox"
                                  name={category.name}
                                  checked={sideMenuCheckboxes[category.name]}
                                  onChange={handleCheckboxChange}
                                />
                                {getCategoryName(category)}
                              </label>
                            </div>
                          ) : null)}
                      />
                    }
                  </div>
                )}
              />

            )}


            <br></br>
            {/* Grade */}
            <div className="main-title" onClick={toggleGradeSection}>
              {t("labels.stem_grade")} {isGradeOpen ? "▼" : "▲"}
            </div>

            {isGradeOpen && (
              <List
                grid={{ xs: 1, sm: 1, md: 1, column: 1 }}
                dataSource={grades}
                renderItem={(grade) => (
                  <div key={grade.name}>
                    <label className="checkbox-title">
                      <input
                        type="checkbox"
                        name={grade.name}
                        checked={sideMenuGradeCheckboxes[grade.name]}
                        onChange={handleGradeCheckboxChange}
                      />
                      {getGradeName(grade)}
                    </label>
                  </div>
                )}
              />

            )}
          </div>
        </div>


        <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
          <div className="content">

            {isLoadingSimulation ? (<div className="loading"></div>) : (<div className="list">
              <List
                grid={{
                  gutter: 24, xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3
                }} // Adjust column number as needed
                dataSource={selectedSimulations}
                renderItem={(item) => (
                  <div className="simulation-card">
                    <List.Item>
                      <Card
                        hoverable
                        key={item.id}
                        style={{
                          margin: "0 auto",
                          width: "100%",  // Full width of the grid item
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        cover={
                          <div className="card-cover" >
                            <img
                              alt="Thumbnail"
                              src={`data:image/png;base64,${item.thumbnail}`}
                              style={{
                                width: "100%",
                                height: "auto",
                              }}
                            />

                          </div>
                        }


                        onClick={() => {
                          addSimulation(item._id, item.embed);
                          setType("stem");
                        }}

                      >
                        <Meta
                          title={
                            <div
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Ensures text doesn't overflow the card
                                fontSize: "16px",
                              }}>
                              {getSimulationName(item)}
                            </div>}
                        />
                      </Card>
                    </List.Item>
                  </div>


                )}
              />
            </div>)}



          </div>

        </div>
      </div>

    </>

  );
});