import { useEffect, useState } from "react";
import { useDevice } from "./App";
import { AppState } from "../types";
import { GetLoginedUser } from "../utils";
import { IconLogoCollab } from "./newIcons";
import { Cascader, CascaderProps, Select, Tag } from "antd";
import { Popover } from "./Popover";
import { AppMenu } from "./AppMenu";
import clsx from "clsx";
import Stack from "./Stack";
import { Section } from "./Section";
import { WebEmbeds } from "./WebEmbed";
import { Profile } from "./Profile";
import { Pagination } from "./Pagination";
import { Zoom } from "./Zoom";
import { ActionManager } from "../actions/manager";
import React from "react";
import { t } from "../i18n";
import { point } from "../ga";
import { getCurrBoardMode, setCurrBoardMode } from "../imago-app/data/localStorage";
import { BOARD_MODE } from "../constants";
import { useAtom } from "jotai";
import { currCollabModeAtom } from "../imago-app/collab/Collab";


export const AppFooter: React.FC<{
  appState: AppState;
  actionManager: ActionManager;
  setAppState: React.Component<any, AppState>["setState"];
  onLogoutDelete: () => void;
  onLogoutSave: () => void;
  isCollaborating: boolean;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  onViewBackgroundColorChange?: (color: string) => void
}> = ({ appState, actionManager, setAppState, isCollaborating, onLogoutDelete, onLogoutSave, operaPage, onViewBackgroundColorChange }) => {
  const [currCollabMode] = useAtom(currCollabModeAtom);
  const [currMode, setCurrMode] = useState(getCurrBoardMode());
  const [showAppMenu, setShowAppMenu] = useState<boolean>(false);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const popRef = React.useRef<HTMLDivElement>(null);
  const device = useDevice();

  const user = GetLoginedUser();


  useEffect(() => {
    // const handler = (event: PointerEvent) => {

    //   if (!device.isMobile && footerRef.current?.contains(event.target as Node)) {
    //     showAppMenu && setShowAppMenu(false);
    //   }
    // };
    // document.addEventListener("pointerdown", handler, false);
    // return () => document.removeEventListener("pointerdown", handler, false);
  })

  useEffect(() => {
    setCurrMode(getCurrBoardMode())
  }, [currCollabMode])

  return <div className="App-footer" ref={footerRef}>
    <div className="App-system-menu" style={{ zIndex: 1000 }}>

      <div onClick={(e) => {
        setShowAppMenu(!showAppMenu);
        e.stopPropagation();
      }} style={{ display: "flex", gap: "15px" }}>
        <div>
          {IconLogoCollab}
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

          <Tag>{t(`pricing.${user?.roleEn.toLowerCase()}`)}{user?.isFreeTrial ? ` - ${t("winTabList.free_trial")}` : ""}</Tag>
        </div>
      </div>



      <div onClick={(e) => {
        setShowAppMenu(false);
        e.stopPropagation();
      }} style={{ cursor: "default" }}>


        <select
          disabled={isCollaborating}
          className="switch-mode"
          value={currMode}
          onChange={({ target }) => {
            setCurrBoardMode(target.value)
            setCurrMode(Number(target.value))
          }}
        >
          <option key="board_mode1" value="1">
            {t("labels.select_board_mode_win.war_room_name")}
          </option>
          <option key="board_mode2" value="2">
            {t("labels.select_board_mode_win.moderator_name")}
          </option>
          <option key="board_mode3" value="3">
            {t("labels.select_board_mode_win.class_room_name")}
          </option>

        </select>

        {/* <Tag>
          {getCurrBoardMode() === BOARD_MODE.warRoom && (<>WarROOM</>)}
          {getCurrBoardMode() === BOARD_MODE.moderator && (<>moderator</>)}
          {getCurrBoardMode() === BOARD_MODE.classRoom && (<>classRoom</>)}
        </Tag> */}
      </div>

    </div>
    {
      showAppMenu && <Popover left={15} bottom={46} zIndex={1000} onCloseRequest={(e) => {
        // e.target !== popRef.current && setShowAppMenu(false);;
        //console.log(pickerButton.current == e.target)

      }} >
        <div ref={popRef} style={{ pointerEvents: "all" }}>
          <AppMenu
            appState={appState}
            actionManager={actionManager}
            operaPage={operaPage}
            setAppState={setAppState}
            onViewBackgroundColorChange={onViewBackgroundColorChange}
            onClick={() => { setShowAppMenu(false); }}
          />
        </div>
      </Popover>
    }
    <div
      className={clsx("layer-ui__wrapper__footer-left zen-mode-transition", {
        "layer-ui__wrapper__footer-left--transition-left":
          appState.zenModeEnabled,
      })}
    >
      <Stack.Col style={{ zIndex: 1000 }}>
        <Section heading="canvasActions" className="left-section">

          <div className="terms-nav-link">
            <a href="https://collab.imago.us/privacy-policy" target="_blank">{t("home_welcome.privacy")}</a>
            <a href="https://collab.imago.us/terms-of-service" target="_blank">{t("home_welcome.terms")}</a>
          </div>


          <Profile
            onDelete={onLogoutDelete}
            onSave={onLogoutSave}
            appState={appState}
            setAppState={setAppState}
            operaPage={operaPage}
            actionManager={actionManager}
          />
          {/* <Pagination
            appState={appState}
            actionManager={actionManager}
            setAppState={setAppState}
          /> */}

          {/* <div className="App-toolbar__divider" style={{ margin: 0 }}></div>
          <Zoom
            appState={appState}
            actionManager={actionManager}
            setAppState={setAppState}
          /> */}
          {/* <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            /> */}
        </Section>
        {/*<Island
          padding={1}
          className={clsx("App-toolbar", {
            "zen-mode": appState.zenModeEnabled,
          })}
        >
          
          <Section heading="canvasActions">
            <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            />

            <div className="App-toolbar__divider"></div>

            {!appState.viewModeEnabled && (
              <UndoRedoActions
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-bottom":
                    appState.zenModeEnabled,
                })}
              />
            )}
            {showFinalize && (
              <FinalizeAction
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-left":
                    appState.zenModeEnabled,
                })}
              />
            )}
          </Section> 
        </Island>*/}
      </Stack.Col>
    </div>

  </div>
}