import "./WebEmbed.scss";
import React, { useState } from "react";
import { DraggablePanel } from "./DraggablePanel";
import { AppState, WebEmbed } from "../types";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { Badge, List } from "antd";
import { CollaborateIcon, MinimizeIcon, ShareScreenIcon } from "./icons";
import { useAtom } from "jotai";
import { isFlashCollabingAtom } from "./App";
import { API_URL, EmbedType } from "../constants";
import { isScreenSharingAtom } from "../imago-app/Plaza";
import _ from "lodash";
import { IconFolder } from "./newIcons";
import { t } from "../i18n";
import { collabAPIAtom } from "../imago-app/collab/Collab";
export const WebEmbedIframe: React.FC<{
  webEmbed: WebEmbed;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ webEmbed, appState, setAppState }) => {
  const [collabAPI] = useAtom(collabAPIAtom);
  const handleClose = () => {
    console.log(111)
    const itemZIndex = webEmbed.zIndex;
    collabAPI?.delRoomLink(webEmbed)
    setAppState({
      webEmbed: appState.webEmbed.map((wb) => {
        if (wb.id == webEmbed.id) {
          wb.removed = true;
        }
        if (wb.zIndex > itemZIndex) {
          wb.zIndex = wb.zIndex - 1;
        }
        return wb;
      }),
    });
  };
  const handleMinisize = (position: [number, number]) => {
    setAppState({
      webEmbed: appState.webEmbed.map((wb) => {
        if (wb.id == webEmbed.id) {
          wb.hide = true;
          webEmbed.position = position;
        }
        return wb;
      }),
    });
  };
  const handleMove = (position: [number, number]) => {
    setAppState({
      webEmbed: appState.webEmbed.map((wb) => {
        if (wb.id == webEmbed.id) {
          webEmbed.position = position;
        }
        return wb;
      }),
    });
  };

  const handleFocus = () => {
    const itemZIndex = webEmbed.zIndex;
    setAppState({
      webEmbed: appState.webEmbed.map((wb, i) => {
        if (wb.id == webEmbed.id) {
          wb.zIndex = appState.webEmbed.filter(w => !w.removed).length;
        } else {
          if (wb.zIndex > itemZIndex) {
            wb.zIndex = wb.zIndex - 1;
          }
        }
        return wb;
      }),
    });
  };


  return (webEmbed.removed ? null :
    <DraggablePanel
      id={webEmbed.id}
      title={t("winTabList.shareScreen")}
      width={640}
      height={360}
      appState={appState}
      setAppState={setAppState}
      zIndex={2}
      buttons={["minisize", "floating", "maxsize", "close"]}
      currentState={webEmbed.hide ? "minisize" : "default"}
      onClose={handleClose}
      onMinisize={handleMinisize}
      onMove={handleMove}
      onFocus={handleFocus}
      position={webEmbed.position}
    >
      <iframe
        className=""
        width={"100%"}
        height={"100%"}
        style={{ border: 0 }}
        src={webEmbed.url}
        scrolling="no"
        referrerPolicy="no-referrer-when-downgrade"
        title="Embedded Content"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      // sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation allow-downloads"
      />
    </DraggablePanel>
  );
};

export const WebEmbeds: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  showLeftDivider?: boolean;
}> = ({ appState, setAppState, showLeftDivider = true }) => {
  const [isScreenSharing] = useAtom(isScreenSharingAtom);
  const [isFlashCollabing] = useAtom(isFlashCollabingAtom);
  const [showPop, setShowPop] = useState(false);

  return appState.webEmbed.filter(s => !s.removed).length > 0 || isScreenSharing || isFlashCollabing ? (
    <>
      {showLeftDivider && (
        <div className="App-toolbar__divider" style={{ margin: 0 }}></div>
      )}
      <div className="web-embeds">
        {/* <Badge count={appState.webEmbed.filter(s=>s.hide).length} size="small"> </Badge> */}
        <a
          className="web-embeds-title"
          onClick={() => {
            setShowPop(!showPop);
          }}
        >
          {IconFolder}
        </a>

        {showPop && (
          <Popover
            bottom={40}
            left={-5}
            onCloseRequest={() => setShowPop(false)}
          >
            <Island style={{ padding: 0, overflow: "hidden" }}>
              <div className="web-embeds-list">
                <ul>
                  {isFlashCollabing && (
                    <li
                      onClick={() => {
                        setAppState({ showFlashCollabration: true });
                        setShowPop(false);
                      }}
                    >
                      {CollaborateIcon}
                    </li>
                  )}
                  {isScreenSharing && (
                    <li
                      onClick={() => {
                        setAppState({ showScreenSharing: true });
                        setShowPop(false);
                      }}
                    >
                      {ShareScreenIcon}
                    </li>
                  )}

                  {_.orderBy(appState.webEmbed.filter(w => !w.removed), "zIndex", "desc").map((item) => {
                    return (
                      <li
                        key={item.id}
                        onClick={() => {
                          //const newSortedWebEmbeds = [...appState.webEmbed.filter(w=>w.id!=item.id),item].map((w,i)=>{w.zIndex = i+1;return w})
                          const itemZIndex = item.zIndex;
                          setAppState({
                            webEmbed: appState.webEmbed.map((wb, i) => {
                              if (wb.id == item.id) {
                                wb.zIndex = appState.webEmbed.filter(w => !w.removed).length;
                                if (wb.hide) {
                                  wb.hide = false;
                                  wb.position = [0, 0]
                                }
                              } else {
                                if (wb.zIndex > itemZIndex) {
                                  wb.zIndex = wb.zIndex - 1;
                                }
                              }
                              //wb.zIndex = appState.webEmbed.filter(w=>!w.removed).length+1; 
                              // if (wb.id == item.id && wb.hide) {
                              //   wb.hide = false;


                              // }else{
                              //   if(wb.zIndex>item.zIndex ){
                              //     wb.zIndex = wb.zIndex-1;
                              //   }
                              // }
                              return wb;
                            }),
                          });
                          setShowPop(false);
                        }}
                      >
                        {EmbedType[item.type].icon}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Island>
          </Popover>
        )}
      </div>
    </>
  ) : null;
};
