import { Popover } from "antd";
import React, { useEffect, useState } from "react"
import { AppState } from "../types";
import { TranslateNoColourIcon } from "./icons";
import { IconTranslate } from "./newIcons";
import { TranslateAIPopupChat } from "./TranslateAIPopup";



export const AiChatTranslateWin: React.FC<{
  appState: AppState;
  setAppState?: React.Component<any, AppState>["setState"];
  textValue: string;
  translateResult: (translatedText: string) => void;
}> = React.memo(({ appState, setAppState, textValue, translateResult }) => {

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };


  return (
    <Popover
      content={
        <div>
          <TranslateAIPopupChat
            textValue={textValue}
            appState={appState}
            translateResult={(translatedText) => {
              hide();
              translateResult(translatedText);
            }}
            setAppState={setAppState}
          /></div>}
      trigger="click"
      placement="right"
      arrow={false}
      onOpenChange={handleOpenChange}
      open={open}
      getPopupContainer={(triggerNode) => triggerNode
      }>
      <a
        href={undefined}
        style={{ cursor: 'pointer', textDecoration: 'none', paddingTop: "3px" }}
      >
        {IconTranslate}
      </a>
    </Popover>
  );
});
