import { AppState } from "../types";
import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import { TranslateAIPopup } from "./TranslateAIPopup";
import { ImagoElement, ImagoTextElementWithContainer } from "../element/types";

export function TranslateAI({
    textValue,
    label,
    ownerElement,
    name,
    icon,
    appState,
    setAppState
}: {
    label: string;
    textValue: string | null;
    ownerElement: readonly ImagoElement[];
    name: string;
    icon: JSX.Element;
    appState: AppState;
    setAppState: React.Component<any, AppState>["setState"]

}) {

    const [isActive, setActive] = React.useState(false);

    const content =
        <div>
            <TranslateAIPopup
                textValue={textValue}
                appState={appState}
                ownerElement={ownerElement}
                setAppState={setAppState}
            />
        </div>;

    return (
        <Popover content={content} trigger="click" placement="right" arrow={false} onOpenChange={setActive} getPopupContainer={(triggerNode) => triggerNode}
        >
            <button
                name={name}
                className={isActive ? "active" : ""}
                aria-label={label}
                onClick={() => { setActive(!isActive) }}
            >
                {icon}
            </button>
        </Popover>
    );
}