import React, { useEffect, useRef, useState } from "react";
import { AppState, StickyNote } from "../types";
import { DraggablePanel } from "./DraggablePanel";
import "./StickyNotePanel.scss";
import { IconColors, IconTranslate, IconTrash, IconZoomIn, IconZoomOut } from "./newIcons";
import { nanoid } from "nanoid";
import { collabAPIAtom, isCollaboratingAtom } from "../imago-app/collab/Collab";
import { useAtom } from "jotai";
import { API_URL } from "../constants";
import { TranslateAIPopupNote } from "./TranslateAIPopup";

export const StickyNoteBackground = [
  "#FFCCE5",
  "#CCFFFF",
  "#CCFFCC",
  "#E6E6FA",
  "#FFFBCC",
  "#FFDAB9",
  "#D3D3D3",
];

export const StickyNotePanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  stickyNote: StickyNote;
  isShowNoteMask: boolean;
  defaultPosition: [number, number];
  getStickyNotes: () => StickyNote[];
}> = ({
  appState,
  setAppState,
  id,
  isShowNoteMask,
  stickyNote,
  defaultPosition,
  getStickyNotes,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const colorSelectRef = useRef<HTMLDivElement | null>(null);
    const [collabAPI] = useAtom(collabAPIAtom);
    const [renderFlag, setRenderFlag] = useState<string>()
    const [showTranslateWin, setShowTranslateWin] = useState(false);
    const [winDefaultWidth, setWinDefaultWidth] = useState<number>(320)

    const getNewsStickyNote = () => {
      return getStickyNotes().filter(s => s.id == stickyNote.id)?.[0]
    }

    const getPosition = () => {
      const rect = ref.current?.getBoundingClientRect();
      return [rect?.x, rect?.y];
    };

    const setShowColorPicker = () => {
      if (colorSelectRef.current) {
        if (colorSelectRef.current.style.display != "flex") {
          colorSelectRef.current.style.display = "flex";
        } else {
          colorSelectRef.current.style.display = "none";
        }
      }
    };

    const toggleExpand = (e: any) => {
      e.stopPropagation();
      const status = stickyNote.status == "expand" ? "fold" : "expand";


      const stickyNoteNew: StickyNote = {
        ...getNewsStickyNote(),
        status,
        position: getPosition() as [number, number],
        key: nanoid(),
      };

      setAppState({
        stickyNotes: [
          ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
          stickyNoteNew,
        ],
      });

      if (collabAPI?.isCollaborating()) {
        collabAPI.syncChangeStickyNote({ stickyNote, stickyNoteNew, actionName: "replace" })
      }

    };

    const remove = (e: any) => {
      e.stopPropagation();

      setAppState({
        stickyNotes: [...getStickyNotes().filter((sn) => sn.id != stickyNote.id)],
      });

      if (collabAPI?.isCollaborating()) {
        collabAPI.syncChangeStickyNote({ stickyNote, actionName: "remove" })
      }
    };

    const setStickyNote = (data: Partial<StickyNote>) => {

      const stickyNoteNew: StickyNote = {
        ...getNewsStickyNote(),
        ...data,
        key: nanoid(),
        position: getPosition() as [number, number],
      };

      const newNotes = [
        ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
        stickyNoteNew,
      ];


      setAppState({
        stickyNotes: newNotes,
      });

      if (collabAPI?.isCollaborating()) {
        collabAPI.syncChangeStickyNote({ stickyNote, stickyNoteNew, actionName: "replace" })
      }

    };

    const setStickyNoteContent = (
      content: string
    ) => {

      const stickyNoteNew: StickyNote = {
        ...stickyNote,
        content,
        position: getPosition() as [number, number],
      };

      const newNotes = [
        ...getStickyNotes().filter((sn) => sn.id != stickyNote.id),
        stickyNoteNew,
      ];

      setAppState({
        stickyNotes: newNotes,
      });

      if (collabAPI?.isCollaborating()) {
        collabAPI.syncChangeStickyNote({ stickyNote: stickyNoteNew, actionName: "update" })
      }

    };

    const toggleTranslateWin = () => {
      if (showTranslateWin) {
        setWinDefaultWidth(320)
      } else {
        setWinDefaultWidth(560)
      }
      setShowTranslateWin(!showTranslateWin)
      setRenderFlag(new Date().getTime().toString())
    }

    useEffect(() => {
      setRenderFlag(new Date().getTime().toString())
    }, [isShowNoteMask]);

    return (
      <DraggablePanel
        key={id}
        id={id}
        title=""
        width={winDefaultWidth}
        renderFlag={renderFlag}
        height={stickyNote.status == "fold" ? 32 : 320}
        appState={appState}
        setAppState={setAppState}
        buttons={[]}
        resizeable={false}
        useActiveTools={false}
        radius={5}
        position={stickyNote.position || defaultPosition}

        onMove={(position) => {
          stickyNote.position = position;
          setAppState({
            stickyNotes: [
              ...appState.stickyNotes.filter((sn) => sn.id != stickyNote.id),
              stickyNote,
            ],
          });

          if (collabAPI?.isCollaborating()) {
            collabAPI.syncChangeStickyNote({ stickyNote, actionName: "update" })
          }


        }}
      >

        <div className="sticky-note-content">

          <div
            ref={ref}
            id={id}
            className="sticky-note imago"
            style={{ background: stickyNote.background }}        >

            <div style={{ width: "100%", height: "100%", position: "relative" }}>
              {isShowNoteMask && (<div
                onPointerDown={(e) => e.stopPropagation()}
                onPointerMove={(e) => e.stopPropagation()}
                onPointerUp={(e) => e.stopPropagation()}
                style={{ width: "100%", height: "100%", background: "#ccc", opacity: "0.2", position: "absolute", pointerEvents: 'auto' }}></div>)}

              <div className="header">
                <div className="title">
                  {stickyNote.status == "fold" ? (
                    <div dangerouslySetInnerHTML={{ __html: stickyNote.content }}></div>

                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <a href={undefined} onClick={(e) => toggleExpand(e)}>
                    {stickyNote.status == "fold" ? IconZoomIn : IconZoomOut}
                  </a>
                  <a href={undefined} onClick={(e) => remove(e)}>
                    {IconTrash}
                  </a>
                </div>
              </div>
              <div
                className="content"
                style={{
                  fontWeight: stickyNote.fontWeight,
                  fontStyle: stickyNote.fontStyle,
                  textDecoration: stickyNote.textDecoration,
                }}
              >

                <div
                  onPointerDown={(e) => e.stopPropagation()}
                  contentEditable="true"
                  //suppressContentEditableWarning={true}
                  onKeyUp={(e) => {
                    e.stopPropagation();
                    let content = e.target.innerHTML;
                    setStickyNoteContent(content);
                  }}
                  dangerouslySetInnerHTML={{
                    __html: stickyNote.content,
                  }}
                >
                </div>

              </div>

              <div
                className="colorSelect"
                ref={colorSelectRef}
                style={{ display: "none" }}
              >
                {StickyNoteBackground.map((b, i) => {
                  return (
                    <a
                      key={i}
                      href={undefined}
                      style={{ background: b }}
                      onClick={() => {
                        setStickyNote({
                          background:
                            stickyNote.background != b ? b : stickyNote.background,
                        });
                      }}
                    ></a>
                  );
                })}
              </div>
              <div className="footer">
                <a
                  href={undefined}
                  onClick={() => {
                    setStickyNote({
                      fontWeight:
                        stickyNote.fontWeight != "bolder" ? "bolder" : "normal",
                    });
                  }}
                  style={{ fontWeight: "bolder" }}
                >
                  B
                </a>
                <a
                  href={undefined}
                  onClick={() => {
                    setStickyNote({
                      fontStyle:
                        stickyNote.fontStyle != "italic" ? "italic" : "normal",
                    });
                  }}
                  style={{ fontStyle: "italic" }}
                >
                  I
                </a>
                <a
                  href={undefined}
                  onClick={() => {
                    setStickyNote({
                      textDecoration:
                        stickyNote.textDecoration != "underline"
                          ? "underline"
                          : "none",
                    });
                  }}
                  style={{ textDecoration: "underline" }}
                >
                  U
                </a>
                <a
                  href={undefined}
                  onClick={() => {
                    setStickyNote({
                      textDecoration:
                        stickyNote.textDecoration != "line-through"
                          ? "line-through"
                          : "none",
                    });
                  }}
                  style={{ textDecoration: "line-through" }}
                >
                  ab
                </a>
                <a
                  href={undefined}
                  onClick={() => {
                    setShowColorPicker();
                  }}
                >
                  {IconColors}
                </a>
                <a
                  href={undefined}
                  onClick={toggleTranslateWin}
                >
                  {IconTranslate}
                </a>
              </div>

            </div>


          </div>
          {showTranslateWin && <div className="translate-part" style={{ background: stickyNote.background }}>

            <TranslateAIPopupNote backgroundColor={stickyNote.background} textValue={stickyNote.content} translateResult={(translatedText) => {

              toggleTranslateWin()

              const translatedStickyNote: StickyNote = {
                id: nanoid(),
                key: nanoid(),
                content: translatedText,
                status: stickyNote.status,
                background: stickyNote.background,
                fontStyle: stickyNote.fontStyle,
                textDecoration: stickyNote.textDecoration,
                fontWeight: stickyNote.fontWeight,
                creatorUserId: stickyNote.creatorUserId,
                position: null
              }
              collabAPI?.addStickyNote(translatedStickyNote)

            }} />

          </div>}

        </div>


      </DraggablePanel>
    );
  };
