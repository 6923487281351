import React, { useEffect, useState } from "react"
import "./SchoolFile.scss"
import { Table, Breadcrumb, Button, Tooltip } from 'antd';
import { AppState, WebEmbed } from "../types";
import { checkLangKey, t } from "../i18n";
import { DownloadIcon } from "../components/icons";
import { API_URL, EmbedType } from "../constants";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import { nanoid } from "nanoid";
import { LocalData } from "../imago-app/data/LocalData";

// export const getStageName = (item: any): string => {
//   // let key = item.nameKey.replace("sim_info_", "");
//   // const sim_key = `data_record.simulation.${key}`;
//   // if (checkLangKey(sim_key)) {
//   //   const v = t(sim_key);
//   //   if (v != "") {
//   //     return v;
//   //   }
//   //   return item.name;
//   // }
//   return item.simType;
// };

export const getSchoolSubject = (selectedSubject: any): string => {
  let key = decodeURIComponent(selectedSubject).toLowerCase().replace(/ /g, "_");
  const sub_level = `data_record.schoolLevel.${key}`;
  if (checkLangKey(sub_level)) {
    const v = t(sub_level);
    if (v !== "") {
      return v;
    }
    return decodeURIComponent(selectedSubject);
  }
  return decodeURIComponent(selectedSubject);
};

export const SchoolFile: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];

}> = ({ appState, setAppState }) => {
  const host = "";
  const [selectedFolders, setSelectedFolders] = useState<any[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<any>();
  const [type, setType] = useState<(keyof typeof EmbedType)>("school")
  const [collabAPI] = useAtom(collabAPIAtom);

  const formatSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = (bytes / Math.pow(1024, i)).toFixed(1);
    return `${size} ${sizes[i]}`;
  };

  const columns = [
    {
      title: t("labels.imago_school_name"),
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => {
        return (
          <span className="hoverable-name">
            {decodeURIComponent(text)}
          </span>
        );
      },
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render: (type: string) => type || '—', 
    // },
    {
      title: t("labels.imago_school_size"),
      dataIndex: 'size',
      key: 'size',
      render: (size: string | undefined) => {
        if (!size || size === '—') {
          return '—';
        }

        const sizeInBytes = parseInt(size, 10);
        if (isNaN(sizeInBytes)) {
          return '—';
        }
        return formatSize(sizeInBytes);
      },
    },
    {
      title: t("labels.imago_school_modified"),
      dataIndex: 'modified',
      key: 'modified',
      render: (modifiedDate: string) => {
        const date = new Date(modifiedDate);
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).format(date);
        return formattedDate;
      },
    },
    {
      title: t("labels.imago_school_action"),
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_: any, record: any) => (
        <span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Tooltip title="Download">
              <Button
                icon={DownloadIcon}
                onClick={() => handleDownload(record.name)}
                style={{
                  marginRight: 8,
                }}
                type="text"
              ></Button>
            </Tooltip>
          </div>
        </span>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const selectedCategory = localStorage.getItem('selectedCategory');
        const selectedLevel = localStorage.getItem('selectedLevel');
        const selectedSubject = localStorage.getItem('selectedSubject');
        setSelectedSubject(selectedSubject)

        if (!selectedCategory || !selectedLevel || !selectedSubject) {
          console.warn("No selected category found in localStorage");
          return;
        }

        const currCategory = encodeURIComponent(selectedCategory);

        const books = await LocalData.imagoSchoolStorage.getSubjectBooks(currCategory, selectedLevel, selectedSubject)

        if (books.length == 0) {
          setLoading(true)

          fetch(`${host}/schoolapi/v1/school/${encodeURIComponent(selectedCategory)}/${selectedLevel}/${selectedSubject}`).then(async (res) => {
            const rawXMLResponse = await res.json();
            setLoading(false)
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(rawXMLResponse, 'application/xml');

            const hrefElements = xmlDoc.getElementsByTagName('d:href');
            const typeElements = xmlDoc.getElementsByTagName('d:resourcetype');
            const sizeElements = xmlDoc.getElementsByTagName('d:getcontentlength');
            const modifiedElements = xmlDoc.getElementsByTagName('d:getlastmodified');
            const contentTypeElements = xmlDoc.getElementsByTagName('d:getcontenttype');

            const folderData = Array.from(hrefElements).map((href, index) => {
              const path = href.textContent;
              if (path && index != 0) {
                const resourceType = typeElements[index]?.textContent;
                const contentType = contentTypeElements[index]?.textContent || 'Unknown';
                const sizeElement = sizeElements[index - 1];
                const size = sizeElement ? sizeElement.textContent : '—';
                const modified = modifiedElements[index]?.textContent || '—';

                const type = resourceType ? 'Folder' : contentType.split('/')[1] || 'Unknown';

                return {
                  name: path.split("/").filter(Boolean).pop() || "",
                  path: path,
                  type: type,
                  size: resourceType ? '—' : size,
                  modified: modified,
                };
              }
              return null;
            }).filter((folder) => folder !== null);
            LocalData.imagoSchoolStorage.saveSubjectBooks(currCategory, selectedLevel, selectedSubject, folderData);
            setSelectedFolders(folderData);
          }).catch(() => {
            setLoading(false)
          });

        } else {
          setSelectedFolders(books);
        }


      } catch (error) {
        console.error("Error fetching or parsing the data:", error);
      }
    })();
  }, []);

  const handleDownload = async (selectedFile: string) => {
    try {
      const selectedCategory = localStorage.getItem('selectedCategory');
      const selectedLevel = localStorage.getItem('selectedLevel');
      const selectedSubject = localStorage.getItem('selectedSubject');

      if (!selectedCategory || !selectedLevel || !selectedSubject) {
        throw new Error('Some required information is missing.');
      }

      // Call the backend to get the file
      const response = await fetch(`${host}/schoolapi/v1/school/${encodeURIComponent(selectedCategory)}/${encodeURIComponent(selectedLevel)}/${encodeURIComponent(selectedSubject)}/${encodeURIComponent(selectedFile)}`);

      if (!response.ok) {
        throw new Error(`Failed to fetch the file: ${response.statusText}`);
      }

      // Download the file
      const blob = await response.blob();

      const decodedFileName = decodeURIComponent(selectedFile);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodedFileName;  // Filename with spaces
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  //   const getBreadcrumbNav = () => {
  //     const selectedCategory = decodeURIComponent(localStorage.getItem('selectedCategory') || 'Category');
  //     const selectedLevel = decodeURIComponent(localStorage.getItem('selectedLevel') || 'Level');
  //     const selectedSubject = decodeURIComponent(localStorage.getItem('selectedSubject') || 'Subject');

  //     return [
  //       selectedCategory,
  //       selectedLevel,
  //       selectedSubject,
  //   ];
  // };

  // const breadcrumbItems = getBreadcrumbNav();


  const handleBack = () => {
    setAppState({
      openDialog: "schoolSubject",
      openSidebar: null,
      lastOpenSidebar: "marketPlace"
    });
    localStorage.removeItem('selectedFile');
  };

  const handleRowClick = (record: any) => {
    try {
      const selectedFile = record.name;
      const selectedCategory = localStorage.getItem('selectedCategory');
      const selectedLevel = localStorage.getItem('selectedLevel');
      const selectedSubject = localStorage.getItem('selectedSubject');

      if (!selectedFile || !selectedCategory || !selectedLevel || !selectedSubject) {
        console.warn('Some required information is missing for PDF viewing.');
        return;
      }

      const bookId = "book_" + nanoid()
      const linkType = "school";
      let pdfPath = `${window.location.origin}/schoolapi/v1/school/pdf/${encodeURIComponent(selectedCategory)}/${encodeURIComponent(selectedLevel)}/${encodeURIComponent(selectedSubject)}/${encodeURIComponent(selectedFile)}`;

      setPdfUrl(pdfPath);

      const webEmbed: WebEmbed = {
        url: pdfPath,
        id: bookId,
        hide: false,
        type: linkType,
        creatorUserId: appState.userInfo?.id,
        zIndex: appState.webEmbed.filter(w => !w.removed).length + 1
      }

      setAppState({
        openDialog: null,
        webEmbed: [
          ...appState.webEmbed,
          webEmbed,
        ],

      });
      collabAPI?.addRoomLink(webEmbed)
    } catch (error: any) { }
  };

  return (
    <div className="school-file">
      <div className="content">
        <div className="table-container">
          {/* <Breadcrumb style={{ margin: '16px 0' }}>
                  {breadcrumbItems.map((item, index) => (
                      <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                  ))}
          </Breadcrumb>
          <h2>{t("labels.imago_school_file")}</h2> */}

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <Button onClick={handleBack}
              style={{ marginRight: '8px', fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', lineHeight: '1' }}>
              ←
            </Button>
            <h2 style={{ margin: 0 }}>{getSchoolSubject(selectedSubject)}</h2>
          </div>

          <div className="list">

            {loading ? (<div className="loading"></div>) : (<Table
              className="table"
              dataSource={selectedFolders}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record);
                  setType("school");
                },
              })}
            />)}


          </div>

        </div>
      </div>
    </div>
  );
};