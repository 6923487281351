import "./TranslateAssist.scss";
import { IconSwap } from "./newIcons"
import { AppState } from "../types";
import { t } from "../i18n";
import React, { useState, useEffect } from "react";
import Select, { SingleValue, StylesConfig } from "react-select";
import { Button } from "antd";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";

type LanguageOption = {
    label: string;
    value: string;
};

const languages: LanguageOption[] = [
    { label: "Arabic", value: "ar" },
    { label: "Afrikaans", value: "af" },
    { label: "Albanian", value: "sq" },
    { label: "Basque", value: "eu" },
    { label: "Belarusian", value: "be" },
    { label: "Bulgarian", value: "bg" },
    { label: "Catalan", value: "ca" },
    { label: "Chinese (Simplified)", value: "zh_CN" },
    { label: "Chinese (Traditional)", value: "zh_TW" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },
    { label: "Danish", value: "da" },
    { label: "Dutch", value: "nl" },
    { label: "English", value: "en" },
    { label: "Estonian", value: "et" },
    { label: "Filipino", value: "fil" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },
    { label: "Galician", value: "gl" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Haitian", value: "ht" },
    { label: "Hindi", value: "hi" },
    { label: "Hungarian", value: "hu" },
    { label: "Icelandic", value: "is" },
    { label: "Indonesian", value: "id" },
    { label: "Irish", value: "ga" },
    { label: "Italian", value: "it" },
    { label: "Japanese", value: "ja" },
    { label: "Korean", value: "ko" },
    { label: "Latin", value: "la" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
    { label: "Macedonian", value: "mk" },
    { label: "Malay", value: "ms" },
    { label: "Norwegian", value: "no" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese (Brazil)", value: "pt_BR" },
    { label: "Portuguese (Portugal)", value: "pt_PT" },
    { label: "Romanian", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Serbian", value: "sr" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Spanish", value: "es" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },
    { label: "Thai", value: "th" },
    { label: "Turkish", value: "tr" },
    { label: "Ukranian", value: "yk" },
    { label: "Vietlabelse", value: "vi" },
    { label: "Welsh", value: "cy" }
];

export const TranslateAssist: React.FC<{
    appState: AppState;
    setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {

    const API_KEY = 'AIzaSyATBXajvzQLTDHEQbcpq0Ihe0vWDHmO520'

    const [text, setText] = useState<string>("");
    const [detectedLanguage, setDetectedLanguage] = useState<string | null>(null);
    const [detectedLanguageName, setDetectedLanguageName] = useState<string | null>(null);
    const [sourceLanguage, setSourceLanguage] = useState<string>("auto");
    const [targetLanguage, setTargetLanguage] = useState<string>("en");
    const [translatedText, setTranslatedText] = useState<string>("");
    const [autoSourceLanguage, setAutoSourceLanguage] = useState<string | null>("");
    const [collabAPI] = useAtom(collabAPIAtom);

    const decodeHtmlEntities = (text: string): string => {
        const tempElement = document.createElement("textarea");
        tempElement.innerHTML = text;
        return tempElement.value;
    };

    const detectLanguage = async (inputText: string): Promise<string | null> => {
        if (!inputText.trim()) {
            setDetectedLanguage(null);
            setDetectedLanguageName(null);
            return null;
        }
        try {
            const response = await fetch(
                "https://api.wikimedia.org/service/lw/inference/v1/models/langid:predict",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ text: inputText }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to detect language.");
            }
            const data = await response.json();
            let detectedLang = data?.wikicode;

            let detectedLangName = data?.languagename;


            if (detectedLangName === "Chinese(Simplified)") {
                detectedLang = "zh_CN";
            } else if (detectedLangName === "Chinese(Traditional)") {
                detectedLang = "zh_TW";
            } else if (detectedLangName === "Portuguese(Brazil)") {
                detectedLang = "pt_BR";
            } else if (detectedLangName === "Portuguese(Portugal)") {
                detectedLang = "pt_PT";
            } else if (detectedLang === "ms") {
                detectedLangName = "Malay";
            }

            setDetectedLanguage(detectedLang);
            setDetectedLanguageName(detectedLangName);
            return detectedLang;
        } catch (error) {
            console.error("Error detecting language:", error);
            return null;
        }
    };

    const languageOptions: LanguageOption[] = [
        {
            label: `${detectedLanguageName ? `(${detectedLanguageName}) - ` : ""}Auto Detected`,
            value: "auto",
        },
        ...languages,
    ];

    const translateText = async (
        inputText: string,
        sourceLang: string,
        targetLang: string
    ) => {
        if (!inputText.trim() || !sourceLang || !targetLang) {
            return;
        }
        try {
            const response = await fetch(
                "https://translate-pa.googleapis.com/v1/translateHtml",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json+protobuf",
                        "x-goog-api-key": API_KEY,
                    },
                    body: JSON.stringify([[inputText, sourceLang, targetLang], "te"]),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to translate text.");
            }
            const data = await response.json();
            const rawTranslatedText = data[0][0];

            // Decode HTML entities
            const decodedText = decodeHtmlEntities(rawTranslatedText);

            setTranslatedText(decodedText);
        } catch (error) {
            console.error("Error translating text:", error);
        }
    };

    useEffect(() => {
        const autoTranslate = async () => {
            if (text.trim()) {
                const sourceLang =
                    sourceLanguage === "auto" ? await detectLanguage(text) : sourceLanguage;
                setAutoSourceLanguage(sourceLang);
                if (sourceLang && targetLanguage) {
                    translateText(text, sourceLang, targetLanguage);
                }
            } else {
                setTranslatedText("");
            }
        };

        autoTranslate();
    }, [text, sourceLanguage, targetLanguage]);

    const swapLanguages = () => {
        const tempSourceLang = sourceLanguage;

        setSourceLanguage(targetLanguage);
        if (autoSourceLanguage) {
            setDetectedLanguageName(autoSourceLanguage);
        }

        setTargetLanguage(autoSourceLanguage ? autoSourceLanguage : tempSourceLang);
        setText(translatedText);
        setTranslatedText(text);
        setAutoSourceLanguage("");

        if (text.trim()) {
            translateText(text, targetLanguage, sourceLanguage);
        } else {
            setTranslatedText("");
        }
    };

    return (
        <div className="web-action-content">
            <div className="app-translateAssist">
                <div className="identify-section">
                    <div className="identify-section-flex">
                        <h4>{t("labels.translate_detected_language")}</h4>

                        <Select
                            options={languageOptions}
                            onChange={(option: SingleValue<LanguageOption>) =>
                                setSourceLanguage(option?.value || "auto")
                            }
                            placeholder="Translate from"
                            value={languageOptions.find((lang) => lang.value === sourceLanguage)}
                        />
                    </div>

                    <div className="swap-box">
                        <button onClick={swapLanguages} className="swap-button">
                            {IconSwap}
                        </button>
                    </div>


                    <div className="identify-section-flex">
                        <h4>{t("labels.translate_tanslate_to")}</h4>
                        <Select
                            options={languages}
                            onChange={(option: SingleValue<LanguageOption>) =>
                                setTargetLanguage(option?.value || "en")
                            }
                            placeholder="Translate to"
                            value={languages.find((lang) => lang.value === targetLanguage)}
                        />
                    </div>
                </div>


                <div className="translate-section">
                    <div className="translate-section-flex">
                        <textarea
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            placeholder="Enter text"
                            rows={15}
                            className="identify-textarea"
                        />

                        {text && (
                            <button
                                onClick={() => {
                                    setText("");
                                    setSourceLanguage("auto");
                                    setDetectedLanguage(null);
                                    setDetectedLanguageName(null);
                                }}
                                className="clear-button"
                            >
                                ✖
                            </button>
                        )}

                    </div>



                    <div className="translate-text-section-flex ">
                        <textarea
                            value={translatedText}
                            readOnly
                            placeholder="Translation"
                            rows={15}
                            className="translate-textarea"
                        />
                    </div>

                </div>
                <div className="action">
                    <Button type="primary" onClick={() => {
                        collabAPI?.addOriginTranslateText(text, translatedText)
                        setAppState({
                            openSidebar: null,
                        });
                    }} >{t("labels.btn_insert")}</Button>
                </div>
            </div>

        </div>
    );
}