import {
  isSyncableElement,
  SocketUpdateData,
  SocketUpdateDataSource,
} from "../data";

import { CheckCollabMemberInfo, hasDrawAuthAtom, joinCollobCheckStatusAtom, personalBoardUpdateFlagAtom, SendPeronalBoardMemberParam, TCollabClass } from "./Collab";

import { FileId, ImagoElement } from "../../element/types";
import {
  WS_EVENTS,
  FILE_UPLOAD_TIMEOUT,
  WS_SCENE_EVENT_TYPES,
} from "../app_constants";
import { BinaryFileData, BinaryFiles, CollabMember, Page, StickyNote, UserIdleState } from "../../types";
import { trackEvent } from "../../analytics";
import throttle from "lodash.throttle";
import { newElementWith } from "../../element/mutateElement";
import { BroadcastedImagoElement } from "./reconciliation";
import { decryptData, encryptData } from "../../data/encryption";
import { BOARD_MODE, PRECEDING_ELEMENT_KEY } from "../../constants";
import { getCurrBoardMode, getCurrPageFromStorage, setDrawAuth } from "../data/localStorage";
import { GetLoginedUser, UserLogined } from "../../utils";
import { jotaiStore } from "../../jotai";
import { LocalData } from "../data/LocalData";

class Portal {
  collab: TCollabClass;
  socket: SocketIOClient.Socket | null = null;
  socketInitialized: boolean = false; // we don't want the socket to emit any updates until it is fully initialized
  roomId: string | null = null;
  roomKey: string | null = null;
  broadcastedElementVersions: Map<string, number> = new Map();
  broadcastedFileVersions: Map<FileId, number> = new Map();

  constructor(collab: TCollabClass) {
    this.collab = collab;
  }

  // open(socket: SocketIOClient.Socket, id: string, key: string, isHost: boolean) {
  //   this.socket = socket;
  //   this.roomId = id;
  //   this.roomKey = key;

  //   // Initialize socket listeners
  //   this.socket.on("init-room", () => {

  //     if (this.socket) {

  //       this.socket.emit("join-room", this.roomId);
  //       trackEvent("share", "room joined");
  //       this.collab.initCollaborators(this.socket.id, {});
  //     }
  //   });
  //   this.socket.on("new-user", async (_socketId: string) => {
  //     this.broadcastScene(
  //       WS_SCENE_EVENT_TYPES.INIT,
  //       this.collab.getSceneElementsIncludingDeleted(),
  //       /* syncAll */ true,
  //       getCurrPageFromStorage()
  //     );
  //     this.broadcastFiles(this.collab.getFiles())
  //   });
  //   this.socket.on("room-user-change", (clients: string[]) => {
  //     this.collab.setCollaborators(clients);
  //   });



  //   return socket;
  // }


  open({ socket, id, key, isHost, userId, userName }:
    { socket: SocketIOClient.Socket, id: string, key: string, isHost: boolean, userId: string, userName: string }) {
    this.socket = socket;
    this.roomId = id;
    this.roomKey = key;

    this.socket.on("init-room", () => {

      if (this.socket) {

        const param = { roomId: this.roomId, userId: userId, userName: userName };

        this.socket.emit("user-join-room", param);
        trackEvent("share", "room joined");
        this.collab.initCollaborators(this.socket.id, {});
      }
    });


    this.socket.on("new-user", async (_socketId: string) => {
      this.broadcastScene(
        WS_SCENE_EVENT_TYPES.INIT,
        this.collab.getSceneElementsIncludingDeleted(),
        /* syncAll */ true,
        getCurrPageFromStorage()
      );
      this.broadcastFiles(this.collab.getFiles())
    });
    this.socket.on("room-user-change", (clients: string[], members: any[]) => {
      this.collab.setCollaborators(clients);
      this.collab.setCollabMembers(members)
    });


    this.socket.on("join-collab-checked-notify", (authType: string, checkResult: number, roomId: string) => {
      if (authType === "status") {
        jotaiStore.set(joinCollobCheckStatusAtom, checkResult);
      } else if (authType === "draw") {
        setDrawAuth(checkResult)
        jotaiStore.set(hasDrawAuthAtom, checkResult);
      }
    });


    this.socket.on("receive-personal-board-data", async (encryptedDataStr: string, ivStr: string, roomId: string, memberId: string) => {

      const encryptedDataArr = Uint8Array.from(atob(encryptedDataStr), (c) =>
        c.charCodeAt(0),
      );
      const iv = Uint8Array.from(atob(ivStr), (c) => c.charCodeAt(0));
      const encryptedData = encryptedDataArr.buffer;
      const decrypted = await decryptData(iv, encryptedData, key);

      const decodedData = new TextDecoder("utf-8").decode(
        new Uint8Array(decrypted),
      );
      const decryptedData = JSON.parse(decodedData);

      const boardWidth = decryptedData.payload.width;
      const boardHeight = decryptedData.payload.height;
      LocalData.personalBoardStorage.saveBoardSize(memberId, boardWidth, boardHeight);
      const remoteElements = decryptedData.payload.elements as ImagoElement[];
      const localElements = await LocalData.personalBoardStorage.get(memberId)
      remoteElements.forEach((ele) => {
        const index = localElements.findIndex(element => element.id === ele.id);
        if (index === -1) {
          if (!ele.isDeleted) {
            localElements.push(ele);
          }

        } else {
          if (ele.isDeleted) {
            localElements.splice(index, 1);
          } else {
            localElements[index] = ele;
          }
        }
      })
      const sflag = new Date().getTime().toString();
      jotaiStore.set(personalBoardUpdateFlagAtom, memberId + "_" + sflag);
      LocalData.personalBoardStorage.save(memberId, localElements);

    });

    return socket;
  }

  close() {
    if (!this.socket) {
      return;
    }
    this.queueFileUpload.flush();
    this.socket.close();
    this.socket = null;
    this.roomId = null;
    this.roomKey = null;
    this.socketInitialized = false;
    this.broadcastedElementVersions = new Map();
    this.broadcastedFileVersions = new Map();
  }

  isOpen() {
    return !!(
      this.socketInitialized &&
      this.socket &&
      this.roomId &&
      this.roomKey
    );
  }

  async _broadcastSocketData(
    data: SocketUpdateData,
    volatile: boolean = false,
  ) {



    if (this.isOpen()) {
      const currBoardMode = getCurrBoardMode();
      if (currBoardMode == BOARD_MODE.warRoom || this.collab.getJoinCollobCheckStatus() == 1 || this.collab.state.isHost) {
        const json = JSON.stringify(data);
        const encoded = new TextEncoder().encode(json);
        const { encryptedBuffer, iv } = await encryptData(this.roomKey!, encoded);
        const byteArray = new Uint8Array(encryptedBuffer);
        this.socket?.emit(
          volatile ? WS_EVENTS.SERVER_VOLATILE : WS_EVENTS.SERVER,
          {
            roomID: this.roomId,
            encryptedBuffer: [...byteArray],
            // encryptedBuffer: data,
            iv: [...iv]
          }
        );
      }
    }
  }

  queueFileUpload = throttle(async () => {
    try {
      await this.collab.fileManager.saveFiles({
        elements: this.collab.imagoAPI.getSceneElementsIncludingDeleted(),
        files: this.collab.imagoAPI.getFiles(),
      });
    } catch (error: any) {
      if (error.name !== "AbortError") {
        this.collab.imagoAPI.updateScene({
          appState: {
            errorMessage: error.message,
          },
        });
      }
    }

    this.collab.imagoAPI.updateScene({
      elements: this.collab.imagoAPI
        .getSceneElementsIncludingDeleted()
        .map((element) => {
          if (this.collab.fileManager.shouldUpdateImageElementStatus(element)) {
            // this will signal collaborators to pull image data from server
            // (using mutation instead of newElementWith otherwise it'd break
            // in-progress dragging)
            return newElementWith(element, { status: "saved" });
          }
          return element;
        }),
    });
  }, FILE_UPLOAD_TIMEOUT);

  broadcastScene = async (
    updateType: WS_SCENE_EVENT_TYPES.INIT | WS_SCENE_EVENT_TYPES.UPDATE,
    allElements: readonly ImagoElement[],
    syncAll: boolean,
    page?: string
  ) => {
    if (updateType === WS_SCENE_EVENT_TYPES.INIT && !syncAll) {
      throw new Error("syncAll must be true when sending SCENE.INIT");
    }

    // sync out only the elements we think we need to to save bandwidth.
    // periodically we'll resync the whole thing to make sure no one diverges
    // due to a dropped message (server goes down etc).
    const syncableElements = allElements.reduce(
      (acc, element: BroadcastedImagoElement, idx, elements) => {
        if (
          (syncAll ||
            !this.broadcastedElementVersions.has(element.id) ||
            element.version >
            this.broadcastedElementVersions.get(element.id)!) &&
          isSyncableElement(element)
        ) {
          acc.push({
            ...element,
            // z-index info for the reconciler
            [PRECEDING_ELEMENT_KEY]: idx === 0 ? "^" : elements[idx - 1]?.id,
          });
        }
        return acc;
      },
      [] as BroadcastedImagoElement[],
    );

    const data: SocketUpdateDataSource[typeof updateType] = {
      type: updateType,
      payload: {
        elements: syncableElements,
        page: page
      },
    };

    for (const syncableElement of syncableElements) {
      this.broadcastedElementVersions.set(
        syncableElement.id,
        syncableElement.version,
      );
    }

    this.queueFileUpload();

    await this._broadcastSocketData(data as SocketUpdateData, true);
  };

  broadcastIdleChange = (userState: UserIdleState) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["IDLE_STATUS"] = {
        type: "IDLE_STATUS",
        payload: {
          socketId: this.socket.id,
          userState,
          username: this.collab.state.username,
          isHost: this.collab.state.isHost!
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };


  broadcastBoardPageChange = ({ toPage, pageMap, actionNameFlag, pageName }: { toPage: string, pageMap?: any, actionNameFlag?: string, pageName?: string }) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["PAGE_CHANGE"] = {
        type: "PAGE_CHANGE",
        payload: {
          toPage: toPage,
          actionNameFlag: actionNameFlag,
          pageName: pageName
          // pageMap: pageMap
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };


  broadcastTerminateCollaboration = (roomId: string) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["TERMINATE_COLLABORATION"] = {
        type: "TERMINATE_COLLABORATION",
        payload: {
          roomId: roomId
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        false, // volatile
      );
    }
  };


  broadcastLinksChange = ({ linkId, userId, linkUrl, linkType, actionName }: { linkId?: string, userId?: string, linkUrl?: string, linkType?: string, actionName: string }) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["LINK_CHANGE"] = {
        type: "LINK_CHANGE",
        payload: {
          linkId,
          userId,
          linkUrl,
          linkType,
          actionName
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };


  broadcastStickyNoteChange = ({ stickyNote, stickyNoteNew, actionName }: { stickyNote: StickyNote, stickyNoteNew?: StickyNote, actionName: string }) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["STICK_NOTE_CHANGE"] = {
        type: "STICK_NOTE_CHANGE",
        payload: {
          stickyNote,
          stickyNoteNew,
          actionName
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };


  broadcastPageChangeFinished = ({ toPage }: { toPage: string }) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["CHANGE_FINISHED"] = {
        type: "CHANGE_FINISHED",
        payload: {
          toPage: toPage,
        },
      };
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };

  checkMemberPermissionApply = (authType: string, collabMemberInfo: CheckCollabMemberInfo) => {
    if (this.socket?.id) {
      this.socket.emit("check-member-permission-apply", authType, collabMemberInfo);
    }
  };

  batchCheckMemberPermissionApply = (authType: string, checkFlag: number, roomId: string) => {
    if (this.socket?.id) {
      this.socket.emit("batch-check-member-permission-apply", authType, checkFlag, roomId);
    }
  };

  terminateCollaboration = (roomId: string) => {
    if (this.socket?.id) {
      this.socket.emit("terminate-collaboration", roomId);
    }
  };

  async sendPersonalBoardDataToHost(elements: readonly ImagoElement[], memberParam: SendPeronalBoardMemberParam, width: number, height: number) {
    const data: SocketUpdateDataSource["PERSONAL_BOARD_UPDATE"] = {
      type: "PERSONAL_BOARD_UPDATE",
      payload: {
        elements: elements,
        width,
        height,
      },
    };
    const json = JSON.stringify(data);
    const encoded = new TextEncoder().encode(json);
    const { encryptedBuffer, iv } = await encryptData(this.roomKey!, encoded);
    const byteArray = new Uint8Array(encryptedBuffer);
    this.socket?.emit("send-personal-board-data-to-host",
      {
        roomId: memberParam.roomId,
        hostClientId: memberParam.hostClientId,
        hostMemberId: memberParam.hostMemberId,
        memberId: memberParam.memberId,
        encryptedBuffer: [...byteArray],
        // encryptedBuffer: data,
        iv: [...iv]
      }
    );


  }

  applyForCollabAuth = (authType: string, flagVal: number) => {
    if (this.socket?.id) {
      this.socket.emit("apply-for-collab-auth", authType, flagVal);
    }
  };

  broadcastMouseLocation = (payload: {
    pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"];
    button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"];
  }) => {
    if (this.socket?.id) {
      const data: SocketUpdateDataSource["MOUSE_LOCATION"] = {
        type: "MOUSE_LOCATION",
        payload: {
          socketId: this.socket.id,
          pointer: payload.pointer,
          button: payload.button || "up",
          selectedElementIds:
            this.collab.imagoAPI.getAppState().selectedElementIds,
          username: this.collab.state.username,
          isHost: this.collab.state.isHost!
        },
      };
      //console.log("SELF_MOUSE_LOCATION",payload.pointer)
      return this._broadcastSocketData(
        data as SocketUpdateData,
        true, // volatile
      );
    }
  };

  broadcastFiles = async (
    files: BinaryFileData[],
  ) => {
    const fileIds = files.map(f => f.id);

    const data: SocketUpdateDataSource["FILES_ADD"] = {
      type: "FILES_ADD",
      payload: {
        fileIds,
      },
    };


    await this.queueFileUpload();

    await this._broadcastSocketData(data as SocketUpdateData);
  };

  broadcastEscalate = async (code: string) => {
    const data: SocketUpdateDataSource["ESCALATE_GOOGLEMEET"] = {
      type: "ESCALATE_GOOGLEMEET",
      payload: {
        code
      },
    };


    await this._broadcastSocketData(data as SocketUpdateData);
  }

  broadcastViewBackgroundColor = async (page: { pageId: string, backgroundColor: string, gridColor: string }) => {
    const data: SocketUpdateDataSource["CHANGE_BACKGROUND"] = {
      type: "CHANGE_BACKGROUND",
      payload: page,
    };


    await this._broadcastSocketData(data as SocketUpdateData);
  }
}

export default Portal;
